import React, { useMemo } from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";
import { Field, Form } from "react-final-form";
import { IHashtag } from "app/interfaces/requisites";
import { CheckboxField } from "components/form/fields";
import { useGetHashtagsQuery } from "app/services/requisites";
import { useUpdateRestaurantHashtagsMutation } from "app/services/restaurants";

const formatResponse = (data: any, restaurantId: string) => {
  const hashtags = [];
  if(data){
    for(let key in data){
      if(data[key]) hashtags.push(key)
    }
  }

  return {
    restaurantId: restaurantId,
    hashtags: hashtags,
  }
}

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const HashtagsEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const { data } = useGetHashtagsQuery(null);
  const [ updateRestaurant ] = useUpdateRestaurantHashtagsMutation();

  const onSubmit = (values: any) => {
    updateRestaurant( formatResponse(values, restaurant.id) ).unwrap()
      .then( () => setPage("menu") )
  };

  const hashtags = useMemo(() => {
    if(!data) return false;
    let result = {} as any;
    restaurant.hashtags.forEach((hashtag:any) => {
      result[hashtag.id] = true;
    })
    return result;
  }, [data, restaurant])

  const renderItem = data ? data.map( (hashtag: IHashtag) => (
    <div key={hashtag.id} className="col-12 md:col-6 lg:col-4 xl:col-3" >
      <Field
        name={`${hashtag.id}`}
        render={CheckboxField}
        inputId={hashtag.id}
      />
      <label htmlFor={hashtag.id} className="ml-2" style={{ fontSize: '12px' }}>
        #{hashtag.name.toUpperCase()}
      </label>
    </div>
  ) ) : [];

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" onClick={() => setPage('menu')}>
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Хештеги </h4>
        <Form
          onSubmit={onSubmit}
          initialValues={restaurant ? hashtags : null}
          render={({ handleSubmit }) => (
                
            <form onSubmit={handleSubmit} >
              <div className="col-12 p-0">
                <div className="card p-mt-2">
                  <div className="col-12 flex flex-wrap">
                    {renderItem}
                  </div>
                </div>
              </div>
                    
              <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
                <div className="col-12 md:col-6 xl:col-3 ">
                  <Button label="Сохранить" className="col-12 p-button-raised p-button-success "/>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default HashtagsEdit;
