import { api } from './api'

export const questionnaireApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestionnaires: build.mutation<any, null>({
      query: () => {
        return {
          url: '/questionnaires',
          method: 'GET',
        };
      },
    }),

    getQuestionnaireById: build.mutation<any, {id: string}>({
      query: (arg) => {
        return {
          url: `/questionnaires/${arg.id}`,
          method: 'GET'
        };
      },
    }),

    getQuestionnaireTemplateById: build.mutation<any, {id: string, couponId: string}>({
      query: (arg) => {
        return {
          url: `/questionnaires/template/${arg.id}`,
          method: 'GET',
          params: {couponId: arg.couponId}
        };
      },
    }),

    createQuestionnaire: build.mutation<any, Partial<any>>({
      query: (credentials: any) => ({
        url: '/questionnaires',
        method: 'POST',
        body: credentials,
      })
    }),

    getQuestions: build.query({
      query: () => ({ url: '/questions' }),
      providesTags: () => [
        { type: 'Questionnaire' },
      ],
    }),

    createQuestion: build.mutation<any, Partial<any>>({
      query: (credentials: any) => ({
        url: '/questions',
        method: 'POST',
        body: credentials,
      })
    }),

  }),
})

export const { 
  useGetQuestionnairesMutation,
  useGetQuestionnaireByIdMutation,
  useGetQuestionnaireTemplateByIdMutation,
  useCreateQuestionnaireMutation,
  useGetQuestionsQuery,
  useCreateQuestionMutation
} = questionnaireApi;