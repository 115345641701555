import React from "react";
import { Rating } from "primereact/rating";
import { toDate, toTime } from "utils/toDate.util";
import { Button } from "primereact/button";

interface IProps {
  item: any;
  onShowReport: (id: string) => void;
}

export const ReviewItem: React.FC<IProps> = (props: IProps) => {
  const { item, onShowReport } = props;

  return (
    <>
      <div className="col-12 py-0 mb-2" style={{ borderBottom: "1px solid silver"}}>
        <div className="col-12 p-0 flex flex-wrap justify-content-between">
          <div className="flex flex-column">
            <span className="m-0" style={{
              fontSize: "14px",
            }}>Дата посещения</span>
            
            <span className="mt-1" style={{ fontSize: "12px"}}>
              {toDate(item.time)} - {toTime(item.time)}
            </span>
          </div>

          <div className="flex flex-column md:text-right">
            <span className="m-0" style={{
              fontSize: "14px",
            }}>Оценка клиента</span>
            
            <Rating cancel={false} stars={5} disabled value={item.rating} className=" review-rating"/>
          </div>
        </div>
        
        <div className="col-12 p-0 flex flex-wrap justify-content-between">
          <div className="flex flex-column mt-1 mb-2">
              <span className="m-0" style={{
                fontSize: "14px",
              }}>Отзыв</span>
              
            <p className="mb-1 mt-0">{item.review}</p>
          </div>

          <div className="col-12 p-0 mb-4">
            <Button 
              label="Подробнее" 
              className="p-button-success mr-2 custom-button-14px col-12 md:col-3 xl:col-2"
              style={{minWidth: "100px"}}
              onClick={() => onShowReport(item.taskId)} 
            />
          </div>
        </div>
        

      </div>
    </>
  );
}

export default ReviewItem;