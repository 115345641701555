import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useMediaQuery } from "react-responsive";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { SelectField, TextField } from "components/form/fields";
import { IQuestion } from "app/interfaces/questionnaire";
import { ICategory } from "app/interfaces/categories";
import { useGetCategoriesQuestionnaireMutation } from "app/services/categories";
import { useCreateQuestionnaireMutation, useGetQuestionnairesMutation, useGetQuestionsQuery } from "app/services/questionnaire";
import { TabPanel, TabView } from "primereact/tabview";
import QuestionnaireGroupQuestions from "./components/questionnaire.group.questions";


interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const NewQuestionnaireWin: React.FC<IProps> = (props: any) => {
  const { visible, setVisible } = props;
  const [ subcategories, setSubcategories ] = useState<ICategory[]>([]);
  const [ selectedQuestions, setSelectedQuestions ] = useState<any[]>([]);
  const [ selectedSelf, setSelectedSelf ] = useState<any[]>([]);
  const [getCategoriesQuestionnaire, categories ] = useGetCategoriesQuestionnaireMutation();
  const { questions, isLoading } = useGetQuestionsQuery(null, {
    selectFromResult: ({ data, isLoading }) => ({ questions: data, isLoading }),
  });
  const [ createQuestionnaire ] = useCreateQuestionnaireMutation();
  const [ getQuestionnaires ] = useGetQuestionnairesMutation()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => { getCategoriesQuestionnaire(null) }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if(categories?.data) setSubcategories(categories.data)
  }, [categories])

  const setSubcategory = (category: ICategory) => {
    const filter = subcategories
      .filter( (cat: any) => cat.id === category.id)
    
      return filter.length ? filter[0].id : null
  }

  const handleClose = () => {
    setSelectedSelf([])
    setSelectedQuestions([])
    setVisible(false)
  }

  const onSubmit = (values: any) => {
    const waiter = selectedQuestions.map( (q: IQuestion) => ({option: 'waiter', questionId: q.id, priority: q.priority}) )
    const self = selectedSelf.map( (q: IQuestion) => ({option: 'self', questionId: q.id, priority: q.priority}) )
    values.questions = [...waiter, ...self]
    values.categoryId = values.category.id

    createQuestionnaire(values)
      .then( (r: any) => {
        if(r?.error) return;
        handleClose()
        getQuestionnaires(null);
      } )
  }

  if(!visible) return <></>

  return (
    <Dialog 
      header={`Создание анкеты`} 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-6"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%",
        zIndex: '9999'
      } : {} }
      onHide={handleClose}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="col-12 p-0">
            
            <ScrollPanel style={isMobile ? { width: '100%', height: 'calc(100dvh - 158px)' } : { height: 'calc(100dvh - 260px)' } }>

            <div className="col-12 px-0 py-1">
              <Field
                name="category"
                label="Тип анкеты:"
                render={SelectField}
                validate={composeValidators(requiredValidator)}
                settings={{
                  options: subcategories,
                  optionLabel: "name",
                  showClear: true, 
                  placeholder: "Выберите тип анкеты"
                }}
              />
            </div>

            <div className="col-12 px-0 py-1">
              <Field
                name="name"
                label="Наименование:"
                render={TextField}
                validate={composeValidators(requiredValidator)}
                settings={{
                  placeholder: "Напишите название анкеты"
                }}
              />
            </div>

              {!!values?.category && (
                <TabView>
                  <TabPanel header="Официант">
                    <ScrollPanel style={isMobile ? { width: '100%', height: 'calc(100dvh - 198px)' } : { height: 'calc(100dvh - 444px)' }}>
                      <QuestionnaireGroupQuestions questions={questions} selected={selectedQuestions} setSelected={setSelectedQuestions} filter={setSubcategory(values.category)}/>
                    </ScrollPanel>
                  </TabPanel>
                  <TabPanel header="Самообслуживание">
                    <ScrollPanel style={isMobile ? { width: '100%', height: 'calc(100dvh - 198px)' } : { height: 'calc(100dvh - 444px)' }}>
                      <QuestionnaireGroupQuestions questions={questions} selected={selectedSelf} setSelected={setSelectedSelf} filter={setSubcategory(values.category)}/>
                    </ScrollPanel>
                  </TabPanel>
                </TabView>
              )}
            
            </ScrollPanel>
            <div className="mt-1 col-12 flex flex-wrap justify-content-end">

              <Button 
                className="col-12 md:col-6 p-button-success" 
                label="Создать" 
                type="submit" 
                disabled={!values?.category || isLoading}
              />

            </div>
          </form>
        )}
      />
    </Dialog>
  );
}

export default NewQuestionnaireWin;
