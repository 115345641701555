import React from "react";
import ListRestaurantsPage from "./list.restaurants.page";
import { TabPanel, TabView } from "primereact/tabview";

export const MainRestaurantsPage: React.FC = (props: any) => {
  return (
    <>
      <TabView>
        <TabPanel header="Заведения" leftIcon="pi pi-briefcase">
          <ListRestaurantsPage />
        </TabPanel>
      </TabView>
    </>
  );
}

export default MainRestaurantsPage;