import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import { AccountForm } from "./account";
import ListManagersPage from "./list.managers.page";
import { useUser } from "hooks/useUser";

export const MainAccountPage: React.FC = (props: any) => {

  const { user } = useUser()
  
  return (
    <>
      <TabView>
        <TabPanel header="Профиль" leftIcon="pi pi-user">
          <AccountForm />
        </TabPanel>

        {user?.role === 'owner' && (
          <TabPanel header="Менеджеры" leftIcon="pi pi-users">
            <ListManagersPage />
          </TabPanel>
        )}
        
      </TabView>
    </>
  );
}

export default MainAccountPage;