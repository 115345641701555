import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";
import { ScrollPanel } from "primereact/scrollpanel";
import QRCode from 'qrcode'
import { showNorr } from "features/norr/slice";
import { useDispatch } from "react-redux";

const location = window.location;
const hostname = location.hostname;
const protocol = location.protocol;

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const QrEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [ qr, setQr ] = useState<string | null>(null);
  const [ url, setURL ] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!restaurant) return;

    const host = hostname === 'localhost' ? 'localhost:3000' : hostname === 'admin.3raza.com' ? "3raza.com" : "demo.3raza.com";
    const url = protocol + "//" + host + "#/main/restaurant/menu/";
    QRCode.toDataURL(`${url + restaurant.id}`)
      .then(r => {
        setQr(r)
        setURL(url + restaurant.id)
      })
      .catch(err => {
        setQr(null)
        setURL(null)
        dispatch(showNorr({severity: "error", message: err.message}))
      })

  }, [restaurant, showNorr]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > QR-code меню заведения </h4>
        
        <ScrollPanel style={{maxHeight: '470px', height: '470px' }}>
          <div className="col-12 flex justify-content-center flex-column" >
            {!!qr ? (
              <div className="col-12 flex justify-content-center my-4">
                <img src={`${qr}`} alt="qr-code заведения"/>
              </div>
            )
              : `Произошла ошибка: QR-CODE не сформирован `}

              {!!url && 
                <div className="col-12 px-3 text-center">
                  <a 
                    download={`${restaurant.name}.png`} 
                    href={`${qr}`}
                    className="p-button p-component p-button-raised p-button-success"
                  >Скачать qr-code</a>
                  <br />
                  <a 
                    href={url} 
                    className="hidden md:hidden"
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{overflowWrap: "break-word"}}
                  >{url}</a>
                </div>
              }
          </div>
        </ScrollPanel>
        

      </div>

    </div>
  );
}

export default QrEdit;
