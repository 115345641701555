import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useMediaQuery } from "react-responsive";
import { toDateNoTime } from "utils/toDate.util";
import { useGetCouponByIdMutation } from "app/services/coupons";
import { WrapperSpinner } from "components/static";
import CouponView from "./components/coupon.view";
import { ScrollPanel } from "primereact/scrollpanel";
import QuestionnaireTemplateWin from "./questionnaire.template.win";

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  currentId: string | null;
}

export const CouponWin: React.FC<IProps> = (props: any) => {
  const { visible, setVisible, currentId } = props;
  const [ currentIdQuestionnaire, setCurrentIdQuestionnaire ] = useState<string | null>(null)
  const [ visibleQuestionnaire, setVisibleQuestionnaire ] = useState<boolean>(false)
  const [ getCouponById, {data, isLoading} ] = useGetCouponByIdMutation()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleQuestionnaire = (questionnaireId: string, couponId: string) => {
    setCurrentIdQuestionnaire(questionnaireId);
    setVisibleQuestionnaire(true);
  }

  useEffect(() => { if(currentId) getCouponById({id: currentId}) }, [currentId, getCouponById])

  if(!currentId) return <></>

  return (
    <Dialog 
      header={`Счет от ${data ? toDateNoTime(data?.coupon?.order.createdAt) : '-'}`} 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-4"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%",
        zIndex: '9999'
      } : {} }
      onHide={() => { 
        setVisible(false) 
      }}
    >
      <ScrollPanel style={isMobile ? { width: '100%', height: 'calc(100dvh - 144px)' } : {height: 'calc(100dvh - 500px)'}}>
        <WrapperSpinner progress={isLoading} >
          <CouponView coupon={data} showQuestionnaire={handleQuestionnaire}/>
        </WrapperSpinner>
      </ScrollPanel>

      <QuestionnaireTemplateWin 
        visible={visibleQuestionnaire} 
        setVisible={setVisibleQuestionnaire} 
        questionnaireId={currentIdQuestionnaire} 
        couponId={currentId} 
      />
    </Dialog>
  );
}

export default CouponWin;
