import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import CreateOrderForm from "./create.order.form";
import { useQuestionnaires } from "hooks/useQuestionnaires";
import { showNorr } from 'features/norr/slice'
import { useDispatch } from "react-redux";
import { select } from "features/questionnaire/slice";
import { useCreateCouponMutation, useCreateOrderMutation } from "app/services/coupons";
import QrWin from "./windows/qr.win";

export const initialValuesQr = {
  restaurant: null,
  quantityShoppers: 25,
  maxPrice: 1000,
  sum: 0,
  comment: 'Покупка купона на участие в акции',
  confirmation: true,
  accept: true
}

export const CreateOrdersPage: React.FC = (props: any) => {
  const [ visible, setVisible ] = useState(false);
  const { selected } = useQuestionnaires()
  const dispatch = useDispatch();
  const [createOrder, {data}] = useCreateOrderMutation()
  const [ createCoupon ] = useCreateCouponMutation()

  const onSubmit = (values: any) => {
    if(!values.accept) return dispatch(showNorr({severity: "warn", message: 'Подтвердите условия договора оферты'}))
    if(!selected) return dispatch(showNorr({severity: "warn", message: 'Анкета не выбрана'}))
    const dataResponse = {
      restaurantId: values.restaurant.id,
      amount: values.sum,
      // amount: 1,
      typePayment: "qr",
      quantityShoppers: +values.quantityShoppers,
      questionnaire: selected
    }
    createOrder(dataResponse)
      .then( (r: any) => {
        createCoupon({...dataResponse, paymentNumber: r.data.paymentNumber})
          .then((r: any) => { 
            if(r?.error) return ;

            setVisible(true)
          })
      })
      
  }

  useEffect(() => {
    return () => { dispatch(select(null)) }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="col-12 p-0 text-center">
        <h3>Покупка купона на участие в акции</h3>
        <Form
          onSubmit={onSubmit}
          initialData={initialValuesQr}
          styles={{minWidth: '320px'}}
          render={({ handleSubmit }) => (
            <CreateOrderForm handleSubmit={handleSubmit} />
          )}
        />

        <QrWin visible={visible} setVisible={setVisible} qrData={data} />
      </div>
    
  );
}

export default CreateOrdersPage;