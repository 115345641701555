import React, { useEffect, useState } from 'react';

import { Field, useForm } from 'react-final-form';
import { composeValidators, requiredValidator } from 'components/form/validators';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';

interface IProps {
  isOne: any[];
  setIsOne: (a: any) => any;
  type: string | null;
}

const QuestionTypesForm: React.FC<IProps> = (props: any) => {
  const { type, isOne, setIsOne } = props;
  const [id, setId] = useState(0)
  const form = useForm ();

  useEffect( () => {
    if(type === 'isOne' && isOne.length === 0) {
      setIsOne([
        {id: 0, value: 'Да', comment: false, balls: '0'},
        {id: 1, value: 'Нет', comment: false, balls: '0'}
      ])

      form.change(`answer.${'0'}`, 'Да')
      form.change(`answer.${'1'}`, 'Нет')
      setId(2)
    }else {
      isOne.forEach( (i: any) => {
        form.change(`answer.${i.id}`, i.value)
      } )
      
      setId(isOne.length)
    }

  }, [type, isOne] ) // eslint-disable-line react-hooks/exhaustive-deps


  const setComment = (id: string, checked: boolean) => {
    const index = isOne.map( (i: any) => i.id).indexOf(id)
    const arr = isOne.slice();
    if(index !== -1) {
      arr[index].comment = checked;
      setIsOne( arr )
    }
  }

  const isFormFieldValid = (meta: any) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta: any) => {
      return isFormFieldValid(meta) && <small className="p-error" style={{
        fontFamily: "MontserratRegular",
        color: "#eb5e55",
        fontSize: "10px"
      }}>{meta.error}</small>;
  };

  const setValue = (id: string, value: any) => {
    const index = isOne.map( (i: any) => i.id).indexOf(id);
    
    if(index !== -1) {
      const arr = isOne.slice();
      arr[index]['value'] = value;
      setIsOne(arr)
    }
  }

  const setBalls = (id: string, value: any) => {
    if(value.length > 2) return ;
    const index = isOne.map( (i: any) => i.id).indexOf(id);
    
    if(index !== -1) {
      const arr = isOne.slice();
      if (/-/i.test(value)) {
        arr[index]['balls'] = "-";
      }else {
        arr[index]['balls'] = value;
      }
      
      setIsOne(arr)
    }
  }

  const onAddIsOne = (e: any) => {
    e.preventDefault()
    const arr = isOne.slice();
    arr.push({id: id, value: '', comment: false, balls: '0'})
    setId(id + 1)
    setIsOne(arr)
  }

  const onDeleteIsOne = (id: number) => {
    const index = isOne.map( (i: any) => i.id).indexOf(id);
    if(index !== -1) {
      const arr = isOne.slice();
      arr.splice(index, 1);
      form.change(`answer.${id}`, null)
      setIsOne(arr)
    }
  }

  const styleLabel = {
    fontSize: "12px",
    // fontWeight: "100"
  }

  return (
    <>
      {type === null && <></>}
      {type === 'text' && (
        <span className="text-center block"><b>Тип вопроса</b> - Текст</span>
      )}

      {type === 'area' && (
        <span className="text-center block"><b>Тип вопроса</b> - Большой текст</span>
      )}

      {type === 'isOne' && (
        <div className="col-12">  
          
          <span className="text-center block"><b>Тип вопроса</b> - Один из</span>

          <div style={{      
            backgroundColor: "white",
            border: "1px solid #ede8e9",
            borderRadius: "8px",
            padding: "6px 12px"
          }}>
            <label style={{
              fontWeight: "normal",
              fontSize: "12px",
              color: "#908e8e"
            }}>Ответы: </label>

            {isOne.map( (answer: any) => (
              <div className="col-12 p-1" key={answer.id}>
                <div className="p-inputgroup">
                  <span style={{ padding: "1.25rem .5rem 0.25rem 0.25rem" }} className='align-items-center'>
                    <RadioButton checked={true} />
                  </span>

                  <Field name={`answer.${answer.id}`} validate={composeValidators(requiredValidator)} render={({ input, meta }) => (
                    <div style={{width: 'calc(100% - 15px)'}}>
                      
                      <div className="col-12 p-0 flex align-self-center flex-wrap justify-content-between">
                        <div className='col-12 md:col-6 mb-1 px-0'>
                          <label style={styleLabel}>Вариант ответа: </label>
                          <InputText 
                            {...input} 
                            style={{border: "none", padding: "0px", boxShadow: "0px 1px 0px silver", maxWidth: '375px'}}
                            placeholder='Вариант ответа'
                            value={answer.value}
                            onChange={(e) => setValue(answer.id, e.target.value)}
                            
                          />
                        </div>

                        <div className='col-12 md:col-6 mb-1 px-0'>
                          <label style={{...styleLabel, paddingTop: '5px'}} className='md:block md:pl-2'>Кол-во баллов: </label>
                          <InputText 
                            {...input} 
                            style={{border: "none", padding: "0px", boxShadow: "0px 1px 0px silver", marginLeft: "6px", maxWidth: '152px' }}
                            placeholder='0 бал.'
                            value={answer.balls}
                            keyfilter = "int"
                            onChange={(e) => setBalls(answer.id, e.target.value)}
                          />
                        </div>
                        
                        <div className='col-12 md:col-6 mb-1 px-0 flex'>
                          <label style={styleLabel}>Комментарий к ответу: </label>
                          <div className="flex ml-2 mr-2 align-items-center" style={{maxWidth: "32px"}}>
                            <Checkbox inputId="binary" checked={answer.comment} onChange={e => setComment(answer.id, e.checked)} />
                            {answer.comment ? <i className="ml-1" style={styleLabel} >Да</i> : <i className="ml-1" style={styleLabel} >Нет</i>}
                            {/* : <i className="pi pi-exclamation-circle ml-1" ></i> */}
                          </div>
                        </div>
                        
                        <Button 
                            // icon="pi pi-trash" 
                            className="p-button-danger col-12 md:col-6"
                            label='Убрать вариант ответа'
                            onClick={(e) => {
                              e.preventDefault();
                              onDeleteIsOne(answer.id)
                            }}
                          />
                        
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                </div>
              </div>
            ) )}

            <div className="col-12 p-0 flex justify-content-center mb-2">
              <Button 
                icon="pi pi-plus" 
                className="p-button-rounded p-button-info" 
                style={ {width: '1.75rem', height: '1.75rem'} }
                onClick={onAddIsOne}
              />
            </div>
            
          </div>
        </div>
  
      )}
    </>
    
  );
};

export default QuestionTypesForm;