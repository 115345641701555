import React, { useEffect, useState } from 'react';

import { groupBy, orderBy } from 'lodash';
import { IQuestion } from 'app/interfaces/questionnaire';
import { toCategoryQuestion } from 'components/toStatus';

interface IProps {
  questions: IQuestion[];
  option: string;
}

export function sortGroups(array: any[], sorts: string[]){
  const result = [] as any[];
  sorts.forEach( (type: string) => {
    array.forEach( (obj: any) => {
      if (obj.category === type) result.push(obj);
    })
  })

  return result;
}

export const RenderQuestions: React.FC<IProps> = (props: IProps) => {
  const { questions, option } = props;

  const [ groups, setGroups ] = useState<any>({});

  useEffect(() => {
    const _questions = questions
      .filter((i: any) => i.option === option)
      .map( (q: any) => ({...q.question, priority: q.priority}) );
    
    setGroups(groupBy(
      sortGroups(
        _questions, 
        ['main', 'entry', 'order', 'service', 'standard', 'farewell', 'impression']
      ), 'category'))
  }, [questions]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {Object.keys(groups).map((key) => {
        return (
          <div className="p-col-12" key={key}>
            <h4 className="p-m-2">{toCategoryQuestion(key)}</h4>

            <div className="p-d-flex p-flex-wrap">
              {orderBy(groups[key], ['priority'], ['asc']).map( (question: IQuestion) => {
                
                return (
                  <div className="p-p-1 p-col-12 p-md-6" key={question.id}>
                    <div 
                      className="p-field-checkbox question-style" 
                    >
                      <label 
                        htmlFor={question.id} 
                        style={{ cursor: 'pointer'}} 
                        className="p-col-12 p-pr-3"
                      > - {question.question}</label>
                    </div>
                  </div>
                ) 
              } )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default RenderQuestions;
