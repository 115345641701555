import React, { useEffect, useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { toDate } from 'utils/toDate.util';
import { toRoleTemplate } from 'components/toStatus';
import { useGetUserByIdMutation } from 'app/services/users';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'primereact/button';
import { IRestaurant } from 'app/interfaces/restaurants';
import { Chip } from 'primereact/chip';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useDeleteRestaurantManagersMutation } from 'app/services/restaurants';
import BindRestaurantDialog from './bind.restaurant.window';
import DeleteWin from 'features/windows/delete.win';

interface IProps{
  visible: boolean;
  setVisible: (value: boolean) => void;
  currentId: string | null;
}

export const toRestaurants = (restaurants: IRestaurant[], cb: (rest: IRestaurant) => void) => {
  const chips = restaurants.map( rest => (
    <div key={rest.id} className="col-12 flex align-items-center">
      <Chip 
        label={rest.name + ' (' + rest.address + ')'} 
        image={rest?.logo ? '/api/files/' + rest?.logo : '/api/admin/files/default'} 
        className="mr-1 mb-2"
        style={{maxWidth: 'calc(100% - 50px)'}}
      />

      <Button 
        icon="pi pi-times-circle" 
        className="p-button-rounded p-button-danger p-button-text mb-1" 
        aria-label="Cancel"
        onClick={()=> cb(rest)}
      />
      
    </div>
  ) )

  return chips;
}

const UserDialog: React.FC<IProps> = (props: any) => {
  const { currentId, visible, setVisible } = props;
  const [visibleRest, setVisibleRest] = useState(false)
  const [visibleDel, setVisibleDel] = useState(false)
  const [itemDel, setItemDel] = useState<IRestaurant | null>(null)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [ getUserById, {data}] = useGetUserByIdMutation();
  const [deleteRestaurantManagers] = useDeleteRestaurantManagersMutation();

  const handleDeleteShow = (restaurant: IRestaurant) => {
    setItemDel(restaurant)
    setVisibleDel(true)
  }
  const handleDelete = (restaurantId: string) => {
    deleteRestaurantManagers({
      restaurantId: restaurantId,
      managerId: data?.accountId ? data.accountId : null
    }).unwrap().then(() => {
      return getUserById({id: currentId})
    })
  }
  const handleVisible = (visible: boolean) => {
    if(!visible) getUserById({id: currentId})
    setVisibleRest(visible)
  }

  useEffect( () => {
    if(currentId) getUserById({id: currentId});
  }, [currentId, getUserById] )
  

  if(!data) return <></>

  return (
    <Dialog 
      header="Просмотр пользователя"
      visible={visible}
      onHide={setVisible}
      className="col-12 p-0 md:col-10 lg:col-6"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%"
      } : {} }
      contentClassName="pr-2 pl-2"
    >
      <ScrollPanel style={ isMobile ? { width: '100%', height: 'calc(100dvh - 110px)' } : {}}>
        <div className="flex flex-wrap align-items-center justify-content-center">
          <div>
            <img 
              alt={data.name} 
              src={`${data?.avatar ? '/api/files/' + data.avatar : '/api/admin/files/default'}`} 
              width={120}
              height={120}
              style={{ verticalAlign: 'middle', borderRadius: '50%' }} 
              className="m-2"
            />
          </div>
          
          <div className='text-center md:text-left'>
            <p className="m-0"> <strong>{data?.name ? `${data?.surname ? data.surname : ''} ${data?.name}` : 'Не указано'} </strong></p> 
            <p className="m-0">( 
              <i className="px-1">tel: <a href={`tel:${data.phone}`}>{data.phone}</a></i>
              {data.email ? <span>, <i className="px-1">email: <a href={`mailto:${data.email}`}>{data.email}</a></i></span> : <></>} 
            )</p>
            <p className="m-0"> <strong>г. {data.city.name}</strong> </p>
            {toRoleTemplate(data.role)}
            <p className="m-0">Дата рождения: <strong> {data.birthday ? toDate(data.birthday) : <i>-Не указано-</i> } </strong></p>
            <p className="m-0">Дата регистрации: <strong> <i>{toDate(data.createdAt)} </i> </strong> </p>
            <p className="m-0">Последнее посещение: <strong> <i>{toDate(data.visitAt)} </i> </strong> </p>
          </div>
          
        </div>

        {data?.restaurant.length > 0 ? (
          <>
            <Divider />
            <div className="flex flex-wrap justify-center-around" style={{maxWidth: '500px'}}>
              <h3 className="col-12 p-1 block text-center my-1"><u>Привязанные заведения</u></h3>
              {toRestaurants(data.restaurant, handleDeleteShow)}
            </div>
          </>
        ) : (
          <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-center">            
            <div className="col-12 md:col-6 xl:col-3">
              <Button 
                label="Назначить на заведение" 
                className="col-12 p-button-raised p-button-success "
                onClick={() => setVisibleRest(true)}
              />
            </div>
          </div>
        )}
      </ScrollPanel>

      <BindRestaurantDialog visible={visibleRest} setVisible={handleVisible} manager={data} />
      <DeleteWin show={visibleDel} showWindow={setVisibleDel} item={itemDel} deleteItem={() => { 
        if(itemDel) handleDelete(itemDel.id)
      }} text="Вы действительно хотите убрать привязку менеджера? Менеджер больше не сможет управлять настройками данного заведения" />
    </Dialog>
  );
};

export default UserDialog;