import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useMediaQuery } from "react-responsive";
import { WrapperSpinner } from "components/static";
import { ScrollPanel } from "primereact/scrollpanel";
import { useGetTaskByIdMutation } from "app/services/tasks";
import ResponsesQuestions from "./components/responses.questions";
import { Button } from "primereact/button";
import ResponseCommentWin from "./response.comment.win";

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  currentId: string | undefined;
  setCurrentId: (id: string | undefined) => void;
}

export const TasksResponsesWin: React.FC<IProps> = (props: any) => {
  const { visible, setVisible, currentId, setCurrentId } = props;
  const [ showComment, setShowComment ] = useState(false);
  const [ getTaskById, {data, isLoading, error} ] = useGetTaskByIdMutation()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => { 
    if(!currentId) return;
    getTaskById({id: currentId})
  }, [currentId, getTaskById])

  useEffect(() => {
    if(error) return setCurrentId(undefined);
    if(data) setVisible(true)
  }, [error, data]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleResponse = () => {
    setShowComment(true)
  }

  if(!currentId) return <></>

  return (
    <>
    <Dialog 
      header={`Ответы тайного покупателя`} 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%",
        zIndex: '9999'
      } : {} }
      onHide={() => { 
        setVisible(false) 
        setCurrentId(null);
      }}
    >
      <ScrollPanel style={{ width: '100%', height: 'calc(100dvh - 178px)' }}>
        <WrapperSpinner progress={isLoading} >
          <>
            <ResponsesQuestions questionnaire={data ? data : null} />
            <div className="p-2">
              <Button label="Прокомментировать отзыв" onClick={handleResponse} className=" col-12 p-button-raised p-button-info ml-1" />
            </div>
          </>
        </WrapperSpinner>
      </ScrollPanel>
    </Dialog>

    <ResponseCommentWin visible={showComment} setVisible={setShowComment} item={data} />
    </>
  );
}

export default TasksResponsesWin;
