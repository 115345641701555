import React, { useMemo } from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { SelectField, TextAreaField, TextField } from "components/form/fields";
import { useGetCategoriesRestaurantQuery } from "app/services/requisites";
import { useUpdateRestaurantMainMutation } from "app/services/restaurants";
import { ScrollPanel } from "primereact/scrollpanel";

const formatResponse = (data: any) => {
  return {
    restaurantId: data.id,
    name: data.name,
    categoryId: data.category?.id,
    averageReceipt: data.addInfo.averageReceipt,
    description: data?.description ? data.description : null,
    comment: data?.comment ? data.comment : null
  }
}

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const MainEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const { data } = useGetCategoriesRestaurantQuery(null)
  const [updateRestaurant] = useUpdateRestaurantMainMutation()

  const onSubmit = (values: any) => {
    updateRestaurant( formatResponse(values) ).unwrap()
      .then(() => setPage('menu'))
  };

  const initialValues = useMemo(() => {
    if(!restaurant) return null;
    let result = {...restaurant, category: restaurant?.categories[0]};
    return result;
  }, [restaurant])

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Основная информация </h4>

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            
              <form onSubmit={handleSubmit} >
                <ScrollPanel style={{maxHeight: '455px'}}>
                  <div className="flex flex-wrap">
                    <div className="col-12 p-0 flex flex-wrap">
                      
                      <div className="col-12 pb-0">
                        <div className="pt-2">
                          <Field
                            validate={composeValidators(requiredValidator)}
                            name="name" label="Наименование:" render={TextField}
                            settings={{
                              placeholder: "Введите название заведения",
                            }}
                          />
                        </div>

                        <div className="pt-2">
                          <Field
                            validate={composeValidators(requiredValidator)}
                            name="category" label="Категории:" render={SelectField}
                            settings={{
                              options: data,
                              optionLabel: "name", 
                              display: "chip" 
                            }}
                          />
                        </div>

                        <div className="pt-2">
                          <Field
                            validate={composeValidators(requiredValidator)}
                            name="addInfo.averageReceipt" 
                            label="Средний чек на человека:" 
                            render={TextField}
                            settings={{
                              placeholder: "Введите возможную сумму чека",
                              keyfilter: "int"
                            }}
                          />
                        </div>
                        
                      </div>
                    </div>
                    
                    <div className="col-12 pt-0 pb-0 pt-2">
                      <Field
                        name="description" label="Описание:" render={TextAreaField}
                        settings={{
                          placeholder: "Дополнительная информация",
                          rows:3,
                        }}
                      />
                    </div>

                    <div className="col-12 pt-0 pb-0 pt-2">
                      <Field
                        name="comment" label="Комментарий:" render={TextAreaField}
                        settings={{
                          rows:3,
                        }}
                      />
                    </div>
                  </div>
                </ScrollPanel>
              
                <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
                  
                  <div className="col-12 md:col-6 xl:col-3">
                    <Button label="Сохранить" className="col-12 p-button-raised p-button-success "/>
                  </div>
                </div>

              </form>
          )}
        />
      </div>
    </div>
  );
}

export default MainEdit;
