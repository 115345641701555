import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../app/store'
import { couponsApi } from 'app/services/coupons';
import { ICoupon } from 'app/interfaces/coupons'

type CouponsState = {
  list: ICoupon[],
}

const initialState = {
  list: []
}

const slice = createSlice({
  name: 'coupons',
  initialState: initialState as CouponsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        couponsApi.endpoints.getCoupons.matchFulfilled,
        (state, { payload }) => {
          state.list = payload
        }
      )
  },
})

export const { } = slice.actions // eslint-disable-line

export default slice.reducer

export const selectAllCoupons = (state: RootState) => state.coupons.list