import React from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";
import { ScrollPanel } from "primereact/scrollpanel";
import { GalleriaImage } from "./gellery.image";
import AddImagesWin from "../add.images.win";
import { show } from "features/windows/slice";
import { useDispatch } from "react-redux";

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const GalleryEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const dispatch = useDispatch()
  
  const handleAdd = () => {
    dispatch(show({type: 'restaurants', name: 'addImages'}))
  }

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Галерея </h4>
        <ScrollPanel style={{maxHeight: '455px'}}>

          <h3 className="m-2">Текущая галерея</h3>
          <GalleriaImage restaurant={restaurant} />

        </ScrollPanel>

        <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
          <div className="col-12 md:col-6">
            <Button label="Добавить фото" className="col-12 p-button-raised p-button-success " onClick={handleAdd}/>
          </div>
        </div>
      </div>

      <AddImagesWin restaurantId={restaurant.id} setPage={setPage}/>
    </div>  
  );
}

export default GalleryEdit;
