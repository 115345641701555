import React from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import ListOrdersPage from "./list.orders.page";
import ListTasksPage from "./list.tasks.page";
import ListQuestionnairePage from "./list.questionnaire.page";
import { Outlet, useLocation } from "react-router-dom";

export const MainShopperPage: React.FC = (props: any) => {
  const location = useLocation();
  
  if(location.pathname !== '/shopper') return <Outlet />

  return (
    <>
      <TabView>
        <TabPanel header="Покупка купона" leftIcon="pi pi-dollar">
          <ListOrdersPage />
        </TabPanel>
        <TabPanel header="Задания" leftIcon="pi pi-briefcase">
          <ListTasksPage />
        </TabPanel>
        <TabPanel header="Анкеты" leftIcon="pi pi-book">
          <ListQuestionnairePage />
        </TabPanel>
      </TabView>
    </>
  );
}

export default MainShopperPage;