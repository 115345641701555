import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";
import { useGetReviewsRestaurantMutation } from "app/services/restaurants";
import { ScrollPanel } from "primereact/scrollpanel";
import ReviewItem from "./review.item";
import TasksResponsesWin from "pages/shopper/windows/task.responses.win";

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const ReviewsEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [visible, setVisible] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>(undefined)
  const [ getReviewsRestaurant, {data = []} ] = useGetReviewsRestaurantMutation();

  const handleShowQuestions = (reviewId: string) => {
    setCurrentId(reviewId)
  }

  useEffect(()=>{
    if(restaurant) getReviewsRestaurant({id: restaurant.id})
  }, [restaurant]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Отзывы тайных покупателей </h4>
        
        <ScrollPanel style={{maxHeight: '470px', height: '470px' }}>
          <>
            { data.length !== 0 
              ? data.map( (review: any) => <ReviewItem item={review} key={review.id} onShowReport={handleShowQuestions} />)
              : <div className="col-12 text-center">Список отзывов пуст</div>
            }
          </>
        </ScrollPanel>
        

      </div>

      <TasksResponsesWin visible={visible} setVisible={setVisible} currentId={currentId} setCurrentId={setCurrentId}/>
    </div>
  );
}

export default ReviewsEdit;
