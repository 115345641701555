import React 
, { useEffect,
useState }// { useMemo } 
from "react";

import { Button } from "primereact/button";
import SingleUploadImage from "components/upload/single.upload";
import { IRestaurant } from "app/interfaces/restaurants";
// import { ICategory } from "app/interfaces/categories";
import { 
  useGetRefreshRestaurantMutation, 
  useUpdateRestaurantActionMutation, 
  useUpdateRestaurantDeactiveMutation, 
  useUploadLogotypeMutation 
} from "app/services/restaurants";
import { ScrollPanel } from "primereact/scrollpanel";
import { useUser } from "hooks/useUser";
import { useMediaQuery } from "react-responsive";
import ActiveRestaurantWin from "../active.restaurant.win";
import { useDispatch } from "react-redux";
import { show } from 'features/windows/slice';

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const MenuEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [ status, setStatus ] = useState('notActive');
  const [uploadLogotype] = useUploadLogotypeMutation();
  const [ getRestaurantById ] = useGetRefreshRestaurantMutation();
  const [ updateRestaurantAction ] = useUpdateRestaurantActionMutation();
  const [ updateRestaurantDeactive ] = useUpdateRestaurantDeactiveMutation();
  const { user } = useUser()
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  
  const upload = (file: any) => {
    uploadLogotype({ restaurantId: restaurant.id, file})
      .unwrap().then( () => getRestaurantById({ id: restaurant.id }) )
  }

  const deactivate = (restaurantId: string) => {
    updateRestaurantDeactive({restaurantId: restaurant.id})
      .then( (r:any) => {
        if(r.hasOwnProperty('data')){ 
          getRestaurantById({ id: restaurant.id })
          setStatus('notActive')
      } 
    })
  }
  const handleActive = () => {
    if(status === 'notActive') {
      updateRestaurantAction({restaurantId: restaurant.id})
        .then( (r:any) => {
          if(r.hasOwnProperty('data')) {
            getRestaurantById({ id: restaurant.id }) 
            setStatus('active')
          }
        })
    }else {
      dispatch(show({type: 'restaurants', name: 'deactivateRestaurant'}))
    }
  }

  useEffect(()=> {
    if(restaurant?.status) setStatus(restaurant.status)
  }, [restaurant])

  // const isHookah = useMemo(() => {
  //   if(!restaurant) return false;
  //   return restaurant.categories.map( 
  //     (category: ICategory) => category.name
  //   ).includes('Кальянная')
  // }, [restaurant])

  return (
    <div className="col-12 p-0">
      <ScrollPanel style={ isMobile ? { width: '100%', height: 'calc(100dvh - 110px)' } : {height: '526px'}}>
        <div className="flex justify-content-center flex-wrap col-12">
          <SingleUploadImage 
            label={"Загрузить логотип"} 
            onSelectFIle={upload}
            currentImg={restaurant ? restaurant.logo : undefined}
          />
          <div className="col-12 text-center">
            <h4 className="m-1">{restaurant && restaurant.name} <br /> ( {restaurant && `г. ${restaurant.city}, ${restaurant.address}`} )</h4>
          </div>
        </div>
        <div className="col-12 p-0 flex flex-wrap">
          <div className="col-12 md:col-6 p-1">
            <Button label="Основная информация" className="p-button-raised p-button-info p-button-text col-12" onClick={() => setPage('main')} />
          </div>
          
          {/* <Button label="Управляющие" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('managers')} /> */}
          {/* <Button label="Адрес(координаты)" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('address')} /> */}
          
          <div className="col-12 md:col-6 p-1">
            <Button label="Контакты" className="p-button-raised p-button-info p-button-text col-12 " onClick={() => setPage('contacts')} />
          </div>

          <div className="col-12 md:col-6 p-1">
            <Button label="Галерея" className="p-button-raised p-button-info p-button-text col-12" onClick={() => setPage('gallery')} />
          </div>

          <div className="col-12 md:col-6 p-1">
            <Button label="Социальные сети" className="p-button-raised p-button-info p-button-text col-12 " onClick={() => setPage('social')} />
          </div>

          <div className="col-12 md:col-6 p-1">
            <Button label="Кухня" className="p-button-raised p-button-info p-button-text col-12 " onClick={() => setPage('kitchen')} />
          </div>

          <div className="col-12 md:col-6 p-1">
            <Button label="QR - меню" className="p-button-raised p-button-info p-button-text col-12" onClick={() => setPage('qr_cod')} />
          </div>
          
          {/* {isHookah && 
            <Button label="Кальян" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('hookah')} />
          } */}
          
          <div className="col-12 md:col-6 p-1">
            <Button label="Часы работы" className="p-button-raised p-button-info p-button-text col-12" onClick={() => setPage('hours')} />
          </div>

          <div className="col-12 md:col-6 p-1">
            <Button label="Отзывы" className="p-button-raised p-button-info p-button-text col-12" onClick={() => setPage('reviews')} />
          </div>

          
          {/* <Button label="Хештеги" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('hashtags')} /> */}
          
          {user?.role === 'owner' && (

            <div className="col-12 md:col-6 p-1">
              <Button 
                label={`${status === 'notActive' ? 'Активировать заведение' : 'Деактивировать заведение'}`} 
                className="p-button-raised p-button-info p-button-text col-12" 
                onClick={() => handleActive()} 
              />
            </div>
            
          )}

        </div>
      </ScrollPanel>

      <ActiveRestaurantWin restaurantId={restaurant?.id} deactivate={ deactivate } />
      
    </div>
  );
}

export default MenuEdit;
