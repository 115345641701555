import React from 'react';
import { Button } from 'primereact/button';
import { IDishes } from 'app/interfaces/restaurants';

interface IProps {
  item: IDishes | null;
  onClick: () => void;
}

const ItemKitchen: React.FC<IProps> = (props: any) => {
  const item = props.item;
  const handleClick = props.onClick;

  return (
    <>
      {item 
        ? <div className="">
            <div className="col-12 shadow-2" style={{fontSize: '12px'}}>
              
              <div className="dishes-grid-item-content flex-start flex-row col-12 p-0" style={{height: 'auto'}}>
                <div className="dishes-grid-item-top col-2 d-flex p-jc-end pl-0 pr-0">
                  <div 
                    className="grid-item-img"
                    style={{
                      backgroundImage:
                        `${item?.photo 
                            ? `url(/api/files/${item.photo})` 
                            : `url(${process.env.PUBLIC_URL + "/images/default_empty.png"})`}`
                    }}
                  />
                </div>

                <div className="d-flex flex-column col-7 md:col-8 justify-content-between">
                  <div>
                    <div className="d-flex">
                      <div className="dishes-name text-center">{item.name}</div>
                    </div>
                    
                    <div className="dishes-description text-start">{item.description}</div>
                  </div>

                  <div className="dishes-name mt-1">Цена: {item.price} <span>&#8381;</span></div>

                  
                </div>
                
                
                <Button onClick={handleClick} icon="pi pi-cog" className="col-1"/>
              </div>
              
              
            </div>

            
          </div>
        : <span></span>
      }
    </>
  );
};

export default ItemKitchen;