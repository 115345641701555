import React from "react";
import MobileMain from "./mobile";
import DesktopMain from "./desktop";
import { useMediaQuery } from "react-responsive";

export const Main: React.FC = (props: any) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  
  return (
    <>
      {isMobile ? <MobileMain /> : <DesktopMain />}
    </>
  );
}

export default Main;