import { IRestaurant } from "app/interfaces/restaurants";
import React from "react";
import styles from "./styles.module.css";

interface IProps {
  item: IRestaurant,
}

export const RestaurantSelect: React.FC<IProps> = (props: IProps) => {
  const { item } = props;

  return (
    <div className={styles.main} 
    // style={{maxWidth: '284px', fontSize: '14px', textWrap: "balance"}}
    >
        <img 
          alt={item.name} 
          src={`${item?.logo ? '/api/files/' + item.logo : '/api/admin/files/default'}`} 
          width={32} 
          height={32}
          style={{ verticalAlign: 'middle', borderRadius: '50%', objectFit: "contain" }} 
          className="p-mr-2 p-mb-2"
        />
        
        <div>
          <span > <strong>{`${item.name}`}</strong> 
          <br/>
          (<i>{item.city.name}</i>)</span>
          <br/>
          <i className="">{item.address}</i>
        </div>
        
      </div>
  );
}

export default RestaurantSelect;