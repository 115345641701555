import React, { useEffect, useState } from 'react';

import { groupBy } from 'lodash';
import { TabPanel, TabView } from 'primereact/tabview';
import { RenderQuestions } from './render.questions';
import { IQuestion } from 'app/interfaces/questionnaire';

interface IProps {
  questions: IQuestion[];
}

const GroupQuestions: React.FC<IProps> = (props: IProps) => {
  const { questions } = props;
  const [ options, setOptions ] = useState<any>({});
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setOptions(groupBy(questions, 'option'))
  }, [questions]);

  useEffect(() => { 
    if(options['waiter']) return setActiveIndex(0)
    if(options['self']) return setActiveIndex(1)
  }, [options]);

  return (
    <>
      <TabView activeIndex={activeIndex} onTabChange={(e) => {
        setActiveIndex(e.index)
      }}>
        {options['waiter'] && 
          <TabPanel header="Официант">
            <RenderQuestions questions={questions} option={'waiter'} />
          </TabPanel>
        }
        
        {options['self'] && 
          <TabPanel header="Самообслуживание">
            <RenderQuestions questions={questions} option={'self'} />
          </TabPanel>
        }
        
      </TabView>
    </>
  );
};

export default GroupQuestions;
