import { api } from './api'

export const tasksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTasks: build.mutation<any, null>({
      query: () => {
        return {
          url: '/tasks',
          method: 'GET',
        };
      },
    }),
    getTaskById: build.mutation<any, {id: string}>({
      query: (arg) => {
        return {
          url: `/tasks/${arg.id}`,
          method: 'GET'
        };
      },
    }),

    putCommentTask: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/tasks/${credentials.id}/comment`,
        method: 'PUT',
        body: credentials,
      })
    }),
    
  }),
})

export const { 
  useGetTasksMutation,
  useGetTaskByIdMutation,
  usePutCommentTaskMutation,
} = tasksApi;