import React from 'react';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { useDispatch } from 'react-redux';
import { hide } from 'features/windows/slice';
import { useWindows } from 'hooks/useWindows';

interface IProps {
  restaurantId: string | null;
  deactivate: (restaurantId: string) => void;
}

const ActiveRestaurantWin: React.FC<IProps> = (props: any) => {
  const { restaurantId, deactivate } = props;
  const dispatch = useDispatch();
  const windows = useWindows();

  const accept = () => deactivate(restaurantId)
  const reject = () => {}

  return <ConfirmDialog visible={windows.restaurants.deactivateRestaurant} onHide={() => dispatch(hide({type: 'restaurants', name: 'deactivateRestaurant'}))} 
      message={`После деактивации, заведение не будет отображаться на сервисе 3Raza. Продолжить?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

export default ActiveRestaurantWin;