import React, { useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { useGetRestaurantsMutation } from "app/services/restaurants";
import { useRestaurants } from "hooks/useRestaurants";
import AddRestaurantWin from "./windows/add.restaurant.win";
import { show } from "features/windows/slice";
import { useDispatch } from "react-redux";
import EditRestaurantWin from "./windows/edit.restaurant.win";
import { useUser } from "hooks/useUser";
import { ViewRestaurants } from "features/restaurants/view.restaurants";

export const ListRestaurantsPage: React.FC = () => {
  const [ currentId, setCurrentId ] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()
  const { filter } = useRestaurants()
  const { user } = useUser()
  const [ getRestaurants ] = useGetRestaurantsMutation()

  const handleAdd = () => dispatch(show({type: 'restaurants', name: 'add'}));
  const handleRefresh = () => getRestaurants(filter);

  return (
    <>

      <div className="col-12 px-0 py-1 flex flex-wrap align-items-baseline justify-content-between">
        <div className="flex flex-wrap">
          {user?.role === 'owner' && (
            <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success mr-1"/>
          )}
            
          <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
        </div>
      </div>

      <ScrollPanel style={{ width: '100%', height: 'calc(100dvh - 200px)' }}>

        <div className="card">
          <ViewRestaurants setElementId={setCurrentId} />
        </div>

      </ScrollPanel>

      <AddRestaurantWin setElementId={setCurrentId} />
      <EditRestaurantWin restaurantId={currentId}/>
    </>
  );
}

export default ListRestaurantsPage;