import React from "react";
import { Outlet } from "react-router-dom"

import MenuMobile from "./layouts/bottombar";
import MobileHeader from "./layouts/mobileHeader";


export const MobileMain: React.FC = (props: any) => {
  
  return (
    <>
      
      <MobileHeader />

      <div className="private-main">

        <div className="p-2 main-page">
          <Outlet />
        </div>

      </div>

      <MenuMobile />
    </>
  );
}

export default MobileMain;