import React from "react";


export const ViewOrdersPage: React.FC = () => {

  return (
    <>

      <p>ViewOrdersPage</p>

    </>
  );
}

export default ViewOrdersPage;