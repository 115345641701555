import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../app/store'
import { categoriesApi } from 'app/services/categories';
import { ICategory } from 'app/interfaces/categories';

type CategoriesState = {
  list: ICategory[],
  questionnaire: ICategory[],
}

const initialState = {
  list: [],
  questionnaire: [],
}

const slice = createSlice({
  name: 'categories',
  initialState: initialState as CategoriesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        categoriesApi.endpoints.getCategories.matchFulfilled,
        (state, { payload }) => {
          state.list = payload
        }
      )
      .addMatcher(
        categoriesApi.endpoints.getCategoriesQuestionnaire.matchFulfilled,
        (state, { payload }) => {
          state.questionnaire = payload
        }
      )
  },
})

export const { } = slice.actions // eslint-disable-line

export default slice.reducer

export const selectAllCategories = (state: RootState) => state.categories.list
export const selectAllCategoriesQuestionnaire = (state: RootState) => state.categories.questionnaire