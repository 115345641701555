import React from "react";
import { Field, Form } from "react-final-form";
import { TextField } from "components/form/fields";
import { IQuestionnaire } from "app/interfaces/questionnaire";
import GroupQuestions from "./group.questions";

interface IProps {
  data: IQuestionnaire | null;
}

export const QuestionnaireView: React.FC<IProps> = (props: any) => {
  const { data } = props;

  if(!data) return <></>

  return (
    <Form
      onSubmit={() => {}}
      initialValues={data}
      styles={{minWidth: '320px'}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="p-col-12 p-p-0">

          <div className="p-col-12">
            <Field
              name="name"
              label="Наименование:"
              render={TextField}
              settings={{
                disabled: true
              }}
            />
          </div>

          <h4 className="p-m-2">Список вопросов</h4>
                
          <GroupQuestions questions={data.questions} />
                
        </form>
      )}
    />
  );
}

export default QuestionnaireView;
