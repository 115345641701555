import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useMediaQuery } from "react-responsive";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { MaskField, MultiSelectField, SelectField, TextAreaField } from "components/form/fields";
import { IQuestion } from "app/interfaces/questionnaire";
import { ICategory } from "app/interfaces/categories";
import { useGetCategoriesQuestionnaireMutation } from "app/services/categories";
import QuestionTypesForm from "./components/question.types.form";
import ActionsBtn from "components/actionsBtn";
import { useCreateQuestionMutation } from "app/services/questionnaire";
import { useDispatch } from "react-redux";
import { showNorr } from 'features/norr/slice'

export const categoriesQuestion = [
  {name: 'Основная информация', value: 'main'},
  {name: 'Вход', value: 'entry'},
  {name: 'Расчёт/прощание', value: 'farewell'},
  {name: 'Впечатления о посещении', value: 'impression'},
  {name: 'Заказ', value: 'order'},
  {name: 'Обслуживание', value: 'service'},
  {name: 'Соответствие стандартам', value: 'standard'},
]

const schema = " #:";

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const NewQuestionWin: React.FC<IProps> = (props: any) => {
  const { visible, setVisible } = props;
  const [type, setType] = useState<string | null>('text')
  const [isOne, setIsOne ] = useState<any[]>([]);
  const [getCategoriesQuestionnaire, {data}] = useGetCategoriesQuestionnaireMutation();
  const [ createQuestion ] = useCreateQuestionMutation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => { getCategoriesQuestionnaire(null) }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const menu = [
    {label: 'Текст', command:()=> setType('text') },
    {label: 'Большой текст', command:()=> setType('area') },
    {label: 'Один из', command:()=> setType('isOne') },
  ]

  const handleClose = () => {
    setVisible(false)
    setType('text')
    setIsOne([])
  }

  const onSubmit = (data: IQuestion) => {
    const subcategory = data.subcategory.map( (i: ICategory) => i.id )
    const variants = type === 'isOne' ? isOne.map( (i) => i.value ).join( schema ) : "";
    const commentBy = type === 'isOne' ? isOne.map( (i) => i.comment ).join( schema ) : "";
    const values = {...data, type, subcategory, variants, commentBy, questionOther: false }
    if(type === 'isOne') values.balls = isOne.map( (i) => +i.balls ).join( schema )

    createQuestion(values)
      .then((r: any) => {
        if(r?.error) return;
        dispatch(showNorr({severity: "success", message: 'Вопрос отправлен на рассмотрение администрации'}))
        handleClose()
      })
  }

  return (
    <Dialog 
      header={`Создание вопроса`} 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%",
        zIndex: '9999'
      } : {} }
      onHide={() => { 
        handleClose() 
      }}
    >
      
        <>
        <p className="text-center p-0" style={{fontSize: '12px'}}><i>Здесь вы можете создать необходимый для вас "Вопрос". 
          <br/> После подтверждения администрации он будет отображаться в общем списке.</i></p>

          <Form
            onSubmit={onSubmit}
            initialValues={{}}
            styles={{minWidth: '320px'}}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="col-12 p-0">
                <ScrollPanel style={{ width: '100%', height: `calc(100dvh - ${isMobile ? '232px' : '324px' })` }}>
                <div className="col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="subcategory"
                    label="Категории заведений:"
                    render={MultiSelectField}
                    settings={{
                      options: data,
                      optionLabel: "name",
                      showClear: true, 
                      placeholder: "Выберите категории заведений"
                    }}
                  />
                </div>

                <div className="col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="category"
                    label="Категория:"
                    render={SelectField}
                    settings={{
                      options: categoriesQuestion,
                      optionLabel: "name",
                      showClear: true, 
                      placeholder: "Выберите категорию"
                    }}
                  />
                </div>

                <div className="col-12">
                  <div className="p-inputgroup">
                    
                    <Field
                      validate={composeValidators(requiredValidator)}
                      name="question"
                      label="Вопрос:"
                      render={TextAreaField}
                      classNameWrapper="border-radius-tr-0 border-radius-br-0"
                      styleWrapper={{width: "100%"}}
                      settings={{
                        placeholder: "Напишите свой вопрос",
                        rows:1,
                      }}
                    />
                    <ActionsBtn className="" menuItems={menu} popupId='create-question'/>
                  </div>
                </div>

                <QuestionTypesForm type={type} isOne={isOne} setIsOne={setIsOne} />

                {type !== 'isOne' && (
                  <div className="col-12">
                    <Field
                      validate={composeValidators(requiredValidator)}
                      name="balls"
                      label="Баллы:"
                      render={MaskField}
                      settings={{
                        placeholder: "Укажите кол-во баллов за вопрос",
                        mask: "0"
                      }}
                    />
                  </div>
                ) }
                </ScrollPanel>
                <div className="mt-1 col-12 flex flex-wrap justify-content-between">
                  <Button className="col-12 md:col-5 xl:col-4 p-button-success" label="Отправить" type="submit"/>
                </div>
              </form>
            )}
          />
        </>
    </Dialog>
  );
}

export default NewQuestionWin;
