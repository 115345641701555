import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../app/store'
import { IFiltersRestaurants, IRestaurant } from 'app/interfaces/restaurants'
import { restaurantsApi } from 'app/services/restaurants'

type RestaurantState = {
  list: IRestaurant[],
  filters: IFiltersRestaurants
}

const initialState = {
  list: [], 
  filters: {
    categories: [],
    cities: []
  }
}

const slice = createSlice({
  name: 'restaurants',
  initialState: initialState as RestaurantState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        restaurantsApi.endpoints.getRestaurants.matchFulfilled,
        (state, { payload }) => {
          state.list = payload
        }
      )
  },
})

export const { } = slice.actions // eslint-disable-line

export default slice.reducer

// export const selectAuth = (state: RootState) => state.auth.isAuthenticated
export const selectAllRestaurants = (state: RootState) => state.restaurants.list
export const selectFiltersRestaurants = (state: RootState) => state.restaurants.filters