import React, { useEffect, useState } from 'react';

import { groupBy } from 'lodash';
import { Form } from 'react-final-form';
import DataContent from 'pages/shopper/components/questions.data.content';
import EntryContent from 'pages/shopper/components/questions.entry.content';
import OrderContent from 'pages/shopper/components/questions.order.content';
import ServiceContent from 'pages/shopper/components/questions.service.content';
import StandardContent from 'pages/shopper/components/questions.standard.content';
import FarewellContent from 'pages/shopper/components/questions.farewell.content';
import ImpressionContent from 'pages/shopper/components/questions.impression.content';
import { toDateNoTime } from 'utils/toDate.util';

interface IProps {
  questionnaire: any;
}

export const ResponsesQuestions: React.FC<IProps> = (props: IProps) => {
  const { questionnaire } = props;
  const [ option, setOption ] = useState<string>("");
  const [ data, setData ] = useState<any>(null);

  useEffect(() => {
    if(!questionnaire) return;

    let _data = { ...questionnaire, questions: [], time: toDateNoTime(questionnaire.time) };
    
    _data.questions = _data.responses.reduce((a:any, v:any) => (
      { 
        ...a, 
        [v.questionId]: {
          question: 
            v.response === 'true' || v.response === 'false' 
              ? v.response === 'true'
              : v.response,
          comment: v.comment,
          balls: v.balls,
          isLike: !!+v.balls  
        }
      }
    ), {})
    
    const group = groupBy(_data.responses, (item) => item.services)
    
    if(Object.keys(group).length){
      const waiter = group['waiter']?.length ? group['waiter'].length : 0;
      const self = group['self']?.length ? group['self']?.length : 0;

      if(waiter > self) {
        setOption('waiter')
      }
      else if(waiter < self) {
        setOption('self')
      }
    }

    setData(_data)
  },[questionnaire]);


  const formatQuestion = () => {
    return questionnaire && questionnaire.report ? 
    questionnaire.report.questions
        .filter( (q: any) => q.option === option)
        .map( (i: any) => i.question) 
    : []
  }

  return (
    <>
      <Form
        onSubmit={() => {}}
        initialValues={data ? data : null}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="col-12 p-0">
            <DataContent questions={formatQuestion()} disabled />
            <EntryContent questions={formatQuestion()} disabled />
            <OrderContent questions={formatQuestion()} disabled />
            <ServiceContent questions={formatQuestion()} disabled />
            <StandardContent questions={formatQuestion()} disabled />
            <FarewellContent questions={formatQuestion()} />
            <ImpressionContent questions={formatQuestion()} disabled />
          </form>
        )}
      />
    </>
  );
};

export default ResponsesQuestions;
