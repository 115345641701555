import React from 'react';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { IGallery } from 'components/mergeGallery';
import { useDispatch } from 'react-redux';
import { hide } from 'features/windows/slice';
import { useWindows } from 'hooks/useWindows';

interface IProps {
  image: IGallery | null;
  deleteImageGallery: (image: IGallery) => void;
}

const DeleteImageWin: React.FC<IProps> = (props: any) => {
  const { image, deleteImageGallery } = props;
  const dispatch = useDispatch();
  const windows = useWindows();

  const accept = () => deleteImageGallery(image)
  const reject = () => {}

  return <ConfirmDialog visible={windows.restaurants.deleteImage} onHide={() => dispatch(hide({type: 'restaurants', name: 'deleteImage'}))} 
      message={`Вы действительно хотите удалить картинку?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

export default DeleteImageWin;