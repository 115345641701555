import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomNavigation } from  "reactjs-bottom-navigation";
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import { useDispatch } from "react-redux";
import { logout } from 'features/auth/slice'

export const MenuMobile: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [ visible, setVisible ] = useState(false);
  const dispatch = useDispatch()

  const onRedirect = (link: string) => {
    setVisible(false)
    navigate(`/${link}`)
  }

  const  bottomNavItems = [
    {
      title: "",
      onClick: (e:any) => { setVisible(!visible)},
      icon: <i className="pi pi-bars" ></i>, 
    },
    {
      title: "",
      onClick: () => onRedirect("restaurants"),
      icon: <i className="pi pi-briefcase" ></i>
    },
    {
      title: "",
      onClick: () => onRedirect("shopper"),
      icon: <i className="pi pi-wallet" ></i>
    },
    {
      title: "",
      onClick: () => onRedirect("settings"),
      icon: <i className="pi pi-cog" ></i>
    },
  ];

  return (
    <>
      <BottomNavigation
        items={bottomNavItems}
        selected={0}
        onItemClick={(item) =>  console.log(item)}
      />
      <Sidebar visible={visible} position="bottom" onHide={() => setVisible(false)} style={{height: '100dvh'}} icons={() => (
        <div style={{width: '100%', fontSize: '14px'}}>
          <img
            src={process.env.PUBLIC_URL + "/images/logotype.png"}
            alt="3Raza"
            width={82}
          />
        </div>
      )}>
        <div className="col-12 flex flex-wrap">
          <div onClick={() => onRedirect("account")} className="col-12 flex align-items-center link-setting">
            <i className="pi pi-user icon-setting" ></i>
            <span>Аккаунт</span>
          </div>

          <div onClick={() => onRedirect("restaurants")} className="col-12 flex align-items-center link-setting">
            <i className="pi pi-briefcase icon-setting" ></i>
            <span>Заведения</span>
          </div>

          <div onClick={() => onRedirect("shopper")} className="col-12 flex align-items-center link-setting">
            <i className="pi pi-wallet icon-setting" ></i>
            <span>Тайный покупатель</span>
          </div>

          <div onClick={() => onRedirect("settings")} className="col-12 flex align-items-center link-setting">
            <i className="pi pi-cog icon-setting" ></i>
            <span>Настройки</span>
          </div>

          <div onClick={() => dispatch(logout())} className="col-12 flex align-items-center link-setting">
            <i className="pi pi-sign-out icon-setting" ></i>
            <span>Выйти</span>
          </div>

          <Divider />
          
          <a href="https://3raza.com" className="col-12 flex align-items-center link-setting">
            <img
              src={process.env.PUBLIC_URL + "/images/3raza.png"}
              alt="3Raza"
              width={82}
              className="icon-setting"
            />
            <span>Перейти на сайт</span>
          </a>
        </div>
      </Sidebar>
    </>
    
  );
}

export default MenuMobile;