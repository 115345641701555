import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../app/store'
import { questionnaireApi } from 'app/services/questionnaire';
import { IQuestionnaire } from 'app/interfaces/questionnaire'

type QuestionnaireState = {
  list: IQuestionnaire[],
  select: IQuestionnaire | null,
}

const initialState = {
  list: [],
  select: null
}

const slice = createSlice({
  name: 'questionnaire',
  initialState: initialState as QuestionnaireState,
  reducers: {
    select: ( state, action: PayloadAction<IQuestionnaire | null> ) => {
      console.log(action);
      state.select = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        questionnaireApi.endpoints.getQuestionnaires.matchFulfilled,
        (state, { payload }) => {
          state.list = payload
        }
      )
  },
})

export const { select } = slice.actions 

export default slice.reducer

export const selectAllQuestionnaire = (state: RootState) => state.questionnaires.list;
export const selectQuestionnaire = (state: RootState) => state.questionnaires.select;