import React, { useEffect, useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { orderBy } from "lodash";
import { useMediaQuery } from "react-responsive";
import { toFioTemplate, toRestaurantsChips, toRoleTemplate } from "components/toStatus";
import { IManagerRestaurants, IRestaurant } from "app/interfaces/restaurants";
import { toDate } from "utils/toDate.util";
import { useGetManagersListMutation } from "app/services/auth";
import UserDialog from "./user.window";

export const ListManagersPage: React.FC = () => {
  const [ currentId, setCurrentId] = useState<string | null>(null)
  const [ visible, setVisible] = useState<boolean>(false)
  const [ getManagersList, { data, isLoading } ] = useGetManagersListMutation()

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const handleRefresh = () => getManagersList({});
  const handleViewing = (id: string) => { 
    setCurrentId(id)
    setVisible(true)
    
  }
  const handleCloseWin = (value: boolean) => {
    setCurrentId(null)
    setVisible(value)
    handleRefresh()
  }

  const action = (rowData: any) => (
    <div className="col-12 p-0 flex justify-content-end">
      <Button icon="pi pi-info-circle" className="p-button-rounded p-button-info " onClick={() => handleViewing(rowData.accountId)}></Button>
    </div>
  )

  const toRestaurantsTemplate = (
    data: { 
      restaurants: IRestaurant[], 
      role: string, 
      ManagerRestaurants: IManagerRestaurants[]
    }) => {
    
    const _manger = data.ManagerRestaurants.map( i => i.restaurants) as any[]
    const restMan = [].concat.apply([], _manger);
    const restaurants = 
      data.role === 'manager' 
      ? restMan
      : data.restaurants;

    const chips = toRestaurantsChips(restaurants)

    return <div className="flex flex-wrap">
      {chips}
    </div>
  }


  useEffect( () => {
    handleRefresh()
  }, [] ) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>

      <div className="col-12 px-0 py-1 flex flex-wrap align-items-baseline justify-content-between">
        <div className="flex flex-wrap">
            
          <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
        </div>
      </div>

      <ScrollPanel style={{ width: '100%', height: 'calc(100dvh - 200px)' }}>

        <DataTable value={orderBy(data, ['createdAt'], ['desc'])} 
          scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100dvh - 228px)" : ""} 
          responsiveLayout="stack" breakpoint="768px" 
          className="table-mobile-rows" stripedRows loading={isLoading}
        >
          <Column field="fio" header="ФИО" body={(rowData)=>toFioTemplate(rowData)} 
            sortable sortField="fio"
          ></Column>

          <Column field="rest" header="Заведения"
            body={(rowData)=>toRestaurantsTemplate(rowData)}
          ></Column>

          <Column field="role" header="Роль" body={(rowData)=>toRoleTemplate(rowData.role)} 
            sortable sortField="role"
            style={{ maxWidth: '6rem' }}
          ></Column>
              
          <Column field="visitAt" header="Последние посещение" 
            sortable sortField="visitAt" style={{ maxWidth: '11rem' }}
            body={(rowData)=>toDate(rowData.visitAt)}
          ></Column>

          <Column body={action} style={{ maxWidth: '4rem' }}></Column>
        </DataTable>

      </ScrollPanel>

      <UserDialog visible={visible} setVisible={handleCloseWin} currentId={currentId} />
    </>
  );
}

export default ListManagersPage;