import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";
import { Checkbox } from 'primereact/checkbox';
import { Field, Form, useForm } from "react-final-form";
import { MaskField } from "components/form/fields";
import { composeValidators, requiredValidator, timeFormatValidator } from "components/form/validators";
import { useUpdateRestaurantHoursMutation } from "app/services/restaurants";
import { ScrollPanel } from "primereact/scrollpanel";
import { useMediaQuery } from "react-responsive";

const formatResponse = (data: any) => {
  return {
    restaurantId: data.id,
    ...data.workingHours
  }
}

const WorkingForm: React.FC = () => {
  const [ isFullTime, toggleFullTime ] = useState<boolean>(false);
  const form = useForm ();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const timeTemplates = '00:00 - 23:59';

  useEffect(() =>{
    if(isFullTime) {
      form.change(`workingHours.monday`, timeTemplates)
      form.change(`workingHours.tuesday`, timeTemplates)
      form.change(`workingHours.wednesday`, timeTemplates)
      form.change(`workingHours.thursday`, timeTemplates)
      form.change(`workingHours.friday`, timeTemplates)
      form.change(`workingHours.saturday`, timeTemplates) 
      form.change(`workingHours.sunday`, timeTemplates)
    }
  },[isFullTime, form])

  return (
    <div className="card-mini mt-2">

      <div className="col-12 p-field-radiobutton">
        <Checkbox 
          inputId="fullTime" 
          name="fullTime" 
          value="fullTime" 
          onChange={(e:any) => toggleFullTime(e.checked)} 
          checked={isFullTime} 
        />
        <label htmlFor="fullTime" className="ml-2">Круглосуточно</label>
      </div>

      <div className="col-12 p-0 flex flex-wrap">
        <ScrollPanel style={ isMobile ? {height: '370px'} : {height: '318px'}} className="col-12">
          <div className="col-12 flex flex-wrap">
          <div className="col-12 md:col-6 p-1">
            <Field
              validate={composeValidators(requiredValidator, timeFormatValidator)}
              name="workingHours.monday"
              label="Понедельник:"
              render={MaskField}
              settings={{
                placeholder: "00:00 - 23:59",
                mask: "#0:*0 - #0:*0",
                definitions: {
                  "#": /[0-2]/,
                  "*": /[0-5]/
                },
                disabled: isFullTime
              }}
            />
          </div>
          <div className="col-12 md:col-6 p-1">
            <Field
              validate={composeValidators(requiredValidator, timeFormatValidator)}
              name="workingHours.tuesday"
              label="Вторник:"
              render={MaskField}
              settings={{
                placeholder: "00:00 - 23:59",
                mask: "#0:*0 - #0:*0",
                definitions: {
                  "#": /[0-2]/,
                  "*": /[0-5]/
                },
                disabled: isFullTime
              }}
            />
          </div>
          <div className="col-12 md:col-6 p-1">
            <Field
              validate={composeValidators(requiredValidator, timeFormatValidator)}
              name="workingHours.wednesday"
              label="Среда:"
              render={MaskField}
              settings={{
                placeholder: "00:00 - 23:59",
                mask: "#0:*0 - #0:*0",
                definitions: {
                  "#": /[0-2]/,
                  "*": /[0-5]/
                },
                disabled: isFullTime
              }}
            />
          </div>
          <div className="col-12 md:col-6 p-1">
            <Field
              validate={composeValidators(requiredValidator, timeFormatValidator)}
              name="workingHours.thursday"
              label="Четверг:"
              render={MaskField}
              settings={{
                placeholder: "00:00 - 23:59",
                mask: "#0:*0 - #0:*0",
                definitions: {
                  "#": /[0-2]/,
                  "*": /[0-5]/
                },
                disabled: isFullTime
              }}
            />
          </div>
          <div className="col-12 md:col-6 p-1">
            <Field
              validate={composeValidators(requiredValidator, timeFormatValidator)}
              name="workingHours.friday"
              label="Пятница:"
              render={MaskField}
              settings={{
                placeholder: "00:00 - 23:59",
                mask: "#0:*0 - #0:*0",
                definitions: {
                  "#": /[0-2]/,
                  "*": /[0-5]/
                },
                disabled: isFullTime
              }}
            />
          </div>
          <div className="col-12 md:col-6 p-1">
            <Field
              validate={composeValidators(requiredValidator, timeFormatValidator)}
              name="workingHours.saturday"
              label="Суббота:"
              render={MaskField}
              settings={{
                placeholder: "00:00 - 23:59",
                mask: "#0:*0 - #0:*0",
                definitions: {
                  "#": /[0-2]/,
                  "*": /[0-5]/
                },
                disabled: isFullTime
              }}
            />
          </div>
          <div className="col-12 md:col-6 p-1">
            <Field
              validate={composeValidators(requiredValidator, timeFormatValidator)}
              name="workingHours.sunday"
              label="Воскресенье:"
              render={MaskField}
              settings={{
                placeholder: "00:00 - 23:59",
                mask: "#0:*0 - #0:*0",
                definitions: {
                  "#": /[0-2]/,
                  "*": /[0-5]/
                },
                disabled: isFullTime
              }}
            />
          </div>
          </div>
        </ScrollPanel>
      </div>
    </div>
  );
};

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const HoursEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [ updateRestaurant ] = useUpdateRestaurantHoursMutation();

  const onSubmit = (values: any) => {
    updateRestaurant( formatResponse(values) ).unwrap()
      .then( () => setPage("menu") )
  };

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" onClick={() => setPage('menu')}>
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Часы работы </h4>
        <Form
          onSubmit={onSubmit}
          initialValues={restaurant}
          render={({ handleSubmit }) => (
                
            <form onSubmit={handleSubmit} >
              <WorkingForm />

              <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
                <div className="col-12 md:col-6 ">
                  <Button label="Сохранить" className="col-12 p-button-raised p-button-success "/>
                </div>
              </div>
            </form>  
          )}
        />
      </div>
    </div>
  );
}

export default HoursEdit;
