import React, { useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import TableCoupons from "features/orders/table.orders";
import { useGetCouponsMutation } from "app/services/coupons";
import { useNavigate } from "react-router-dom";
import CouponWin from "./windows/coupon.win";

export const ListOrdersPage: React.FC = () => {
  const [ currentId, setCurrentId] = useState<string | null>(null)
  const [ visible, setVisible] = useState<boolean>(false)
  const navigate = useNavigate()
  const [ getCoupons ] = useGetCouponsMutation()

  const handleAdd = () => navigate('/shopper/coupon/new');
  const handleRefresh = () => getCoupons(null);
  const handleViewing = (id: string) => {
    setCurrentId(id)
    setVisible(true)
  }

  return (
    <>
      <div className="col-12 px-0 py-1 flex flex-wrap align-items-baseline justify-content-between">
        <div className="flex flex-wrap">
          <Button label="Новый купон" onClick={handleAdd} className="p-button-raised p-button-success mr-1"/>
            
          <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
        </div>
      </div>

      <ScrollPanel style={{ width: '100%', height: 'calc(100dvh - 200px)' }}>

        <div className="card">
          <TableCoupons onViewing={handleViewing} />
        </div>

      </ScrollPanel>

      <CouponWin currentId={currentId} visible={visible} setVisible={setVisible}/>

    </>
  );
}

export default ListOrdersPage;