import { api } from './api'

export const requisitesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCategoriesRestaurant: build.query({
      query: () => ({ url: '/category/restaurants' }),
      providesTags: () => [
        { type: 'Requisites' },
      ],
    }),
    getCategoriesKitchen: build.query({
      query: () => ({ url: '/kitchen/category' }),
      providesTags: () => [
        { type: 'Requisites' },
      ],
    }),
    getKitchen: build.query({
      query: () => ({ url: '/kitchen/national' }),
      providesTags: () => [
        { type: 'Requisites' },
      ],
    }),
    getHashtags: build.query({
      query: () => ({ url: '/hashtags' }),
      providesTags: () => [
        { type: 'Requisites' },
      ],
    }),
  }),
})

export const { useGetCategoriesRestaurantQuery, useGetCategoriesKitchenQuery, useGetKitchenQuery, useGetHashtagsQuery } = requisitesApi;