import { useGetRestaurantsMutation } from "app/services/restaurants";
import { CheckboxField, SelectField, TextField } from "components/form/fields";
import { aroundQuantityValidator, composeValidators, requiredValidator } from "components/form/validators";
import { useQuestionnaires } from "hooks/useQuestionnaires";
import { useRestaurants } from "hooks/useRestaurants";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { Field, useForm } from "react-final-form";
import SelectQuestionnaireWin from "./windows/select.questionnaire.win";
import { ScrollPanel } from "primereact/scrollpanel";
import RestaurantSelect from "pages/layouts/restaurantTemplate";

interface IProps {
  handleSubmit: (values: any) => void;
}

export const CreateOrderForm: React.FC<IProps> = (props: any) => {
  const {handleSubmit} = props;
  const [ catFilter, setCatFilter ] = useState<string[]>([]);
  const [ showQuestionnaire, setShowQuestionnaire ] = useState(false);
  const { selected } = useQuestionnaires();
  const { restaurants, filter } = useRestaurants()
  const [ getRestaurants ] = useGetRestaurantsMutation()
  const form = useForm ();
  const values = form.getState().values;

  useEffect(() => { getRestaurants(filter) }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleQuestionnaire = (e: any) => {
    e.preventDefault();
    setShowQuestionnaire(true);
  }

  useEffect(() => { form.change('quantityShoppers', 25) }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(values.restaurant) {
      form.change('maxPrice', values.restaurant?.addInfo?.averageReceipt)
      if(values.restaurant?.categories?.length) {
        const categories = values.restaurant?.categories.map( (category: any) => category.category.name);
        setCatFilter(categories);
      }
    }
      
  }, [form, values])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!values.maxPrice) return form.change('sum', 0);
    if(Object.keys(values).length && typeof values.maxPrice) {
      const summary = values.maxPrice.split("-").filter( (sum:string) => sum !== "");
      const length = summary.length;
      const _reduce = summary.reduce((sum:string, current:string) => +sum + +current, 0);
      
      if(length)
        form.change('sum', _reduce/length * 3)
    }
  }, [form, values.maxPrice])// eslint-disable-line react-hooks/exhaustive-deps

  const selectedRestaurantTemplate = (option: any, props: any) => {
    if (option) {
      return <RestaurantSelect item={option}  />;
    }

    return (
      <span> {props.placeholder} </span>
    );
  }
  const restaurantTemplate = (option: any) => {
    return <RestaurantSelect item={option} />;
  }

  useEffect(() => {
    if(!values.maxPrice) return form.change('sum', 0);
    if(Object.keys(values).length && typeof values.maxPrice) {
      const summary = values.maxPrice.split("-").filter( (sum:string) => sum !== "");
      const length = summary.length;
      const _reduce = summary.reduce((sum:string, current:string) => +sum + +current, 0);
      
      if(length)
        form.change('sum', _reduce/length * 3)
    }
  }, [values.accept])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit} >

        <ScrollPanel style={{height: '435px' }}>
          <div className="mt-2 mb-2 px-3">
            <Field
              validate={composeValidators(requiredValidator)}
              name="restaurant" label="Заведение"
              render={SelectField}
              settings={{
                options: restaurants,
                optionLabel: "name",
                // filter: true,
                showClear: true, 
                // filterBy: "name",
                placeholder: "Выберите заведение",
                emptyFilterMessage: "Совпадений не найдено",
                itemTemplate: restaurantTemplate,
                valueTemplate: selectedRestaurantTemplate,
              }}
            />
          </div>  
                  
          <div className='mt-2 mb-2 px-3'>
            <Field
              validate={composeValidators(requiredValidator, aroundQuantityValidator(1, 25))}
              name="quantityShoppers"
              label="Лимит участников"
              render={TextField}
              help="Количество пользователей которые могут принять участие в акции"
              settings={{
                placeholder: "25",
                keyfilter: 'int',
              }}
            />
          </div>

          <div className='mt-2 mb-2 px-3'>
            <Field
              validate={requiredValidator}
              name="maxPrice"
              label="Средний чек"
              render={TextField}
              settings={{
                placeholder: "0",
                keyfilter: 'int',
                disabled: true
              }}
              icon={["fas", "ruble-sign"]}
            />
          </div>

          <div className='mt-2 mb-2 px-3'>
            <Field
              validate={ requiredValidator }
              name="sum" label="Сумма к оплате"
              render={TextField}
              settings={{
                placeholder: "0",
                disabled: true
              }}
            />
          </div>

          <div className='col-12 px-3'>
            <Button 
              label={ selected ? `Выбрано: ${selected.name}` : `Выбрать анкету`} 
              className="p-button-outlined p-button-secondary col-12" 
              onClick={handleQuestionnaire}
              disabled={values?.restaurant ? false : true}
            />
          </div>
        
              
        <div className='mt-2 mb-2' >
          <Field
            name="accept"
            type="checkbox"
            render={CheckboxField}
            inputId="confirmation"
            value={values.accept}
          />
          <label className="pl-2" htmlFor="confirmation" style={{fontSize: '14px'}}>
            Я ознакомился с условиями ret
            <a href="/docs/dogovor-3raza.docx" download> договора оферты </a>
          </label>
        </div>

        </ScrollPanel>

        <div className="col-12 p-0 mt-3 flex flex-wrap justify-content-between">
          <div></div>
          <Button label="Купить купон" className="col-12 md:col-5 mt-1 mb-1 p-button-raised p-button-success" />
        </div>
      </form>

      <SelectQuestionnaireWin 
        visible={showQuestionnaire} 
        setVisible={setShowQuestionnaire} 
        filters={catFilter}
      />
    </>
  );
}

export default CreateOrderForm;