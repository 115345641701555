import React from "react";
import { Dialog } from "primereact/dialog";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  qrData: any
}

export const QrWin: React.FC<IProps> = (props: any) => {
  const { visible, setVisible, qrData } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  if(!qrData) return <></>

  return (
    <Dialog 
      header="Оплата через СБП" 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%"
      } : {} }
      onHide={() => { 
        navigate('/shopper');
        setVisible(false) 
      }}
    >
      <div className="flex flex-column">
        
        {!!qrData ? <img src={`${qrData.qrUrl}`} alt="Оплата по qr-code" width="200"/>
        : `Произошла ошибка: QR-CODE не сформирован `}

        {!!qrData && 
          <a 
            href={qrData.payload} 
            className="block md:hide"
            target="_blank" 
            rel="noopener noreferrer"
          >Ссылка на оплату</a>
        }
        
      </div>
    </Dialog>
  );
}

export default QrWin;
