import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useMediaQuery } from "react-responsive";
import TableQuestionnaire from "features/questionnaire/table.questionnaire";
import QuestionnaireWin from "./questionnaire.win";

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  filters: string[];
}

export const SelectQuestionnaireWin: React.FC<IProps> = (props: any) => {
  const [ currentId, setCurrentId] = useState<string | null>(null)
  const [ show, setShow] = useState<boolean>(false)
  const { visible, setVisible, filters } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleShowQuestions = (id: string) => {
    setCurrentId(id)
    setShow(true)
  }

  return (
    <Dialog 
      header="Выбор анкеты" 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%"
      } : {} }
      onHide={() => { setVisible(false) }}
    >
      <TableQuestionnaire isSelect={true} onViewing={handleShowQuestions} filters={{ categories: filters }}/>

      <QuestionnaireWin visible={show} setVisible={setShow} currentId={currentId} />
    </Dialog>
  );
}

export default SelectQuestionnaireWin;
