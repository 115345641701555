import React, { useEffect, useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useGetRestaurantsMutation, useUpdateRestaurantManagersMutation } from 'app/services/restaurants';
import { useUser } from "hooks/useUser";
import { IUser } from 'app/interfaces/user';
import { useRestaurants } from 'hooks/useRestaurants';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ImageColumn from 'components/table/image.column';

interface IProps{
  visible: boolean;
  setVisible: (value: boolean) => void;
  manager: IUser | null;
}

const BindRestaurantDialog: React.FC<IProps> = (props: any) => {
  const { manager, visible, setVisible } = props;
  const [selected, setSelected] = useState<any | null>(null)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [updateRestaurant] = useUpdateRestaurantManagersMutation();
  const [getRestaurants, {isLoading}] = useGetRestaurantsMutation();
  const { user } = useUser();
  const { restaurants, filter } = useRestaurants()

  const handleMergeManager = () => {
    // restaurantId: string
    if(!user) return; 
    const values = {
      restaurantId: selected.id,
      ownerId: user.accountId,
      managerId: manager.accountId
    }
    updateRestaurant(values).unwrap()
      .then( () => {
        setVisible(false)
      } )
  }

  useEffect(()=>{
    getRestaurants(filter)
  },[]) // eslint-disable-line react-hooks/exhaustive-deps
  

  if(!manager) return <></>

  const imageBody = (rowData: any) => <ImageColumn image={`${rowData.logo}`} />

  return (
    <Dialog 
      header="Привязка заведения"
      visible={visible}
      onHide={setVisible}
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%"
      } : {} }
      contentClassName="pr-2 pl-2"
    >
      <ScrollPanel style={isMobile ? { width: '100%', height: 'calc(100dvh - 164px)' } : {height: 'calc(100dvh - 218px)'}}>
        <DataTable value={restaurants} 
          scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100dvh - 226px)" : ""} 
          responsiveLayout="stack" breakpoint="768px" 
          loading={isLoading}
          selection={selected}
          onSelectionChange={(e) => setSelected(e.value) }
        >
          <Column
            selectionMode="single"
            style={{ maxWidth: '3rem', float: "right" }}
            exportable={false}
          ></Column>
          <Column header="" style={{ maxWidth: '5rem'}} body={imageBody}></Column>
          <Column field="name" header="Название"></Column>
          <Column field="address" header="Адрес"></Column>
        </DataTable>
      </ScrollPanel>

        <div className="restaurants-list-action">
          <Button 
            label="Сохранить" 
            className="col-12 p-button-raised p-button-success "
            onClick={() => handleMergeManager()}
            disabled={!selected}
          />
        </div>
    </Dialog>
  );
};

export default BindRestaurantDialog;