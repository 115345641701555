import React, { useEffect } from "react";

import { Field } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { SelectField, TextAreaField, TextField } from "components/form/fields";
import SingleUploadImage from "components/upload/single.upload";
import { IDishes } from "app/interfaces/restaurants";
import { ICategory } from "app/interfaces/categories";
import { typeGrams } from "../add.dishes.win";

interface IProps{
  setFile: (value: any) => void;
  currentImg?: string;
  categories: ICategory[];
  kitchens: IDishes[]
}

export const DishesFormWin: React.FC<IProps> = (props: any) => {
  const {setFile} = props;

  useEffect(()=>{
    return () => setFile(0)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { categories, kitchens, currentImg } = props;

  return (
    <div className="col-12 p-0 flex flex-wrap">
      <div className="col-12 md:col-6 p-0">
        <div className="col-12 flex justify-content-center">
          <SingleUploadImage 
            label={"Загрузить фотографию"}
            onSelectFIle={setFile}
            currentImg={currentImg ? currentImg : null}
          />
        </div>

        <div className="col-12 p-1">
          <Field
            validate={composeValidators(requiredValidator)}
            name="kitchenId" label="Категория меню:" render={SelectField}
            settings={{
              options: kitchens,
              optionLabel: "name",
              filter: true,
              showClear: true, 
              filterBy: "name",
              placeholder: "Выберите категорию"
            }}
          />
        </div>

        <div className="col-12 p-1">
          <Field
            validate={composeValidators(requiredValidator)}
            name="categoryId" label="Категория:" render={SelectField}
            settings={{
              options: categories,
              optionLabel: "name",
              filter: true,
              showClear: true, 
              filterBy: "name",
              placeholder: "Выберите категорию"
            }}
          />
        </div>

        
      </div>

      <div className="col-12 md:col-6 p-0">

        <div className="col-12 p-1">
          <Field
            validate={composeValidators(requiredValidator)}
            name="name" label="Наименование:" render={TextField}
            settings={{
              placeholder: "Пример: Котлеты из утки с ягодным соусом"
            }}
          />
        </div>

        <div className="col-12 p-1">
          <Field
            validate={composeValidators(requiredValidator)}
            name="description" label="Описание:" render={TextAreaField}
            settings={{
              placeholder: "Пример: Утка, лук, масло растительное, клюквенный соус."
            }}
          />
        </div>

        <div className="col-12 p-1">
          <Field
            validate={composeValidators(requiredValidator)}
            name="price" label="Цена:" render={TextField}
            settings={{ placeholder: "Цена за блюдо", keyfilter: /[\d\/]+$/ }} // eslint-disable-line
          />
        </div>

        <div className="col-12 p-0">
          <div className="col-12 p-0 flex align-items-center">
            <div className="col-8 p-1">
              <Field
                validate={composeValidators(requiredValidator)}
                name="grams" label="Граммовка:" render={TextField}
                settings={{ placeholder: "Пример: 100/50/25", keyfilter: /[\d\/]+$/ }} // eslint-disable-line
              />
            </div>

            <div className="col-4 p-1">
              <Field
                validate={composeValidators(requiredValidator)}
                name="type" label="" render={SelectField}
                settings={{
                  options: typeGrams,
                  optionLabel: "name"
                }}
              />
            </div>
          </div>
                
      </div>

        
      </div>
    </div>
  );
}

export default DishesFormWin;