import React, { useEffect, useState } from 'react';

import { Field } from 'react-final-form';
import { requiredValidator } from 'components/form/validators';
import { TextField } from 'components/form/fields';
import SwitchField from './switch.field';
import { IQuestion } from 'app/interfaces/questionnaire';

interface IProps {
  disabled?: boolean;
  questions: IQuestion[];
}

export const DataContent: React.FC<IProps> = (props: any) => {
  const { 
    // disabled = true, 
    questions } = props;
  const [ filters, setFilter ] = useState<IQuestion[]>([]);
  
  useEffect(() => {
    try {
      const filtered = questions.filter((question: IQuestion) => question.category === 'main')

      setFilter(filtered);
    } catch (error) {
      console.log(error);
    }
    
  }, [questions])
  
  const classField = "col-12 md:col-6 mb-1 style-questions-responses";

  const rerender = filters.map((question: IQuestion) =>{
    return (
      <div className={classField} key={question.id} >
        <SwitchField question={question} disabled />
      </div>
    )
  })

  return (
    <>
      <h3 className="m-2">Основные данные: </h3>
      
        <div className="flex flex-wrap col-12 p-0">
          <div className={classField}>
            <Field
              validate={requiredValidator}
              name="time"
              label="Дата проверки:"
              render={TextField}
              settings={{
                disabled: true
              }}
            />
          </div>

          {rerender}

        </div>
      
    </>
  );
};


export default DataContent;