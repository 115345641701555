import { api } from './api'

export const categoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.mutation<any, null>({
      query: () => {
        return {
          url: '/category/restaurants',
          method: 'GET',
        };
      },
    }),
    getCategoriesQuestionnaire: build.mutation<any, null>({
      query: () => {
        return {
          url: '/category/questionnaire',
          method: 'GET',
        };
      },
    })
  }),
})

export const { 
  useGetCategoriesMutation,
  useGetCategoriesQuestionnaireMutation,
} = categoriesApi;