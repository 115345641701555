import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import { useWindows } from "hooks/useWindows";
import { useDispatch } from "react-redux";
import { hide } from "features/windows/slice";
import { useMediaQuery } from "react-responsive";
import { Button } from "primereact/button";
import { useUploadGalleryMutation } from "app/services/restaurants";
import { ScrollPanel } from "primereact/scrollpanel";
import GalleryForm from "./components/gellery.form";

interface IProps {
  setElementId?: (id: string) => void;
}

interface IProps {
  restaurantId: string | undefined;
  setPage: (page: string) => void;
}

export const AddImagesWin: React.FC<IProps> = (props: IProps) => {
  const { restaurantId, setPage } = props;
  const [files, setFiles] = useState<any>([]);
  const dispatch = useDispatch()
  const windows = useWindows()
  const [upload] = useUploadGalleryMutation();
  
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const handleClose = () => dispatch(hide({type: 'restaurants', name: 'addImages'}));
  const handleSave = () => {
    if(!restaurantId) return;

    upload({restaurantId: restaurantId, gallery: files}).unwrap()
      .then( () => setPage('menu') )
      .then( () => {
        setPage('gallery')
        handleClose()
      } )
  }

  if(!restaurantId) return <></>;

  return (
    <Dialog 
      header="Добавить фото" 
      visible={windows.restaurants.addImages} 
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%",
      } : {
        maxWidth: "480px"
      } }
      onHide={handleClose}
    >

      <ScrollPanel style={{ width: '100%', height: "calc(100dvh - 114px)" }} className="gallery-scroll" >
        <>
          <GalleryForm setFiles={setFiles} files={files} />
        
          <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
            <div className="col-12 md:col-6">
              <Button label="Сохранить" className="col-12 p-button-raised p-button-success " onClick={handleSave}/>
            </div>
          </div>
        </>
      </ScrollPanel>

    </Dialog>
  );
}

export default AddImagesWin;
