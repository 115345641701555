import React, { useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { useGetTasksMutation } from "app/services/tasks";
import TableTasks from "features/tasks/table.tasks";
import { Dropdown } from "primereact/dropdown";
import TasksResponsesWin from "./windows/task.responses.win";

const statuses = [
  {code: 'confirm', name: 'Завершена'},
  {code: 'new', name: 'Свободно'},
  {code: 'start', name: 'Идёт набор'},
  {code: 'active', name: 'В процессе выполнения'},
  {code: 'verification', name: 'На проверке'},
  {code: 'verified', name: 'Проверено'},
  {code: 'not_verified', name: 'Не прошло проверку'},
  {code: 'payout', name: 'Ожидает выплаты'},
  {code: 'reject', name: 'Отменена'},
  
];

export const ListTasksPage: React.FC = () => {
  const [ filters, setFilters] = useState<any>({
    'state': { value: null, matchMode: 'equals' }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [ currentId, setCurrentId ] = useState<string | undefined>(undefined) 
  const [ visible, setVisible] = useState<boolean>(false)
  const [getTasks] = useGetTasksMutation()


  const handleRefresh = () => getTasks(null);
  const handleViewing = (id: string) => {
    setCurrentId(id)
  }

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters1 = { ...filters };
    filters['state'].value = value?.code;

    setFilters(_filters1);
    setGlobalFilterValue(value);
  }

  return (
    <>

      <div className="col-12 px-0 py-1 flex flex-wrap align-items-baseline justify-content-between">
        <Dropdown value={globalFilterValue} options={statuses} 
          onChange={onGlobalFilterChange} 
          className="column-filter m-1" optionLabel="name" showClear
          placeholder="Выберите статус" style={{width: '210px'}}/>

        <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1" />
      </div>

      <ScrollPanel style={{ width: '100%', height: 'calc(100dvh - 200px)' }}>

        <div className="card">
          <TableTasks onViewing={handleViewing} filters={filters} />
        </div>

      </ScrollPanel>

      <TasksResponsesWin visible={visible} setVisible={setVisible} currentId={currentId} setCurrentId={setCurrentId}/>
    </>
  );
}

export default ListTasksPage;