import { ICreateCoupon, ICreateOrder } from 'app/interfaces/questionnaire';
import { api } from './api'

export const couponsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCoupons: build.mutation<any, null>({
      query: () => {
        return {
          url: '/orders',
          method: 'GET',
        };
      },
    }),
    getCouponById: build.mutation<any, {id: string}>({
      query: (arg) => {
        return {
          url: `/orders/${arg.id}`,
          method: 'GET'
        };
      },
    }),
    createOrder: build.mutation<any, Partial<ICreateOrder>>({
      query: (credentials: any) => {
        return {
          url: '/orders/create',
          method: 'POST',
          body: credentials,
        }
      }
    }),
    createCoupon: build.mutation<any, Partial<ICreateCoupon>>({
      query: (credentials: any) => {
        return {
          url: '/orders/coupons/buy',
          method: 'POST',
          body: credentials,
        }
      }
    }),
  }),
})

export const { 
  useGetCouponsMutation,
  useGetCouponByIdMutation,
  useCreateOrderMutation,
  useCreateCouponMutation
} = couponsApi;