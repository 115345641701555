import React from "react";

import { IRestaurant } from "app/interfaces/restaurants";
import MenuEdit from "./menu.edit";
import ContactsEdit from "./contacts.edit";
import ManagersEdit from "./managers.edit";
import MainEdit from "./main.edit";
import GalleryEdit from "./gallery.edit";
import HoursEdit from "./hours.edit";
import HashtagsEdit from "./hashtags.edit";
import HookahEdit from "./hookah.edit";
import KitchenEdit from "./kitchen.edit";
import AddressEdit from "./address.edit";
import SocialEdit from "./social.edit";
import ReviewsEdit from "./reviews.edit";
import QrEdit from './qr.edit';

interface IProps {
  page: string;
  data: IRestaurant;
  setPage: (page: string) => void;
}

export const SwitchEdit: React.FC<IProps> = (props: IProps) => {
  const { data, page, setPage } = props;

  return (
    <>
      {page === 'menu' && ( <MenuEdit restaurant={data} setPage={setPage} /> )}
      {page === 'main' && ( <MainEdit restaurant={data} setPage={setPage} /> )}
      {page === 'address' && ( <AddressEdit restaurant={data} setPage={setPage} /> )}
      {page === 'managers' && ( <ManagersEdit restaurant={data} setPage={setPage} /> )}
      {page === 'contacts' && ( <ContactsEdit restaurant={data} setPage={setPage} /> )}
      {page === 'gallery' && ( <GalleryEdit restaurant={data} setPage={setPage} /> )}
      {page === 'hours' && ( <HoursEdit restaurant={data} setPage={setPage} /> )}
      {page === 'social' && (<SocialEdit restaurant={data} setPage={setPage} />) }
      {page === 'hashtags' && ( <HashtagsEdit restaurant={data} setPage={setPage} /> )}
      {page === 'hookah' && ( <HookahEdit restaurant={data} setPage={setPage} /> )}
      {page === 'kitchen' && ( <KitchenEdit restaurant={data} setPage={setPage} /> )}
      {page === 'qr_cod' && ( <QrEdit restaurant={data} setPage={setPage} /> )}
      {page === 'reviews' && ( <ReviewsEdit restaurant={data} setPage={setPage} /> )}
    </>
  );
}

export default SwitchEdit;
