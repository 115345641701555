import React, { useEffect } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { useGetLoggingMutation } from "app/services/logger";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useMediaQuery } from "react-responsive";
import { toDateNoTime } from "utils/toDate.util";

export const ListLogPage: React.FC = () => {
  const [ getLogging, { data, isLoading } ] = useGetLoggingMutation()

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const handleRefresh = () => getLogging();

  useEffect(() => { getLogging() }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>

      <div className="col-12 px-0 py-1 flex flex-wrap align-items-baseline justify-content-between">
        <div className="flex flex-wrap">
          <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
        </div>
      </div>

      <ScrollPanel style={{ width: '100%', height: 'calc(100dvh - 200px)' }}>

        <div className="card">
          <DataTable value={data} 
            scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100dvh - 200px)" : ""} 
            responsiveLayout="stack" breakpoint="768px" 
            loading={isLoading}
          >
            <Column body={(rowData)=>toDateNoTime(rowData.createdAt)} header="Дата" sortable sortField="createdAt"></Column>
            <Column field="name" header="Исполнитель"></Column>
            <Column field="description" header="Событие"></Column>
          </DataTable>
        </div>

      </ScrollPanel>
    </>
  );
}

export default ListLogPage;