import React from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const HookahEdit: React.FC<IProps> = (props: IProps) => {
  const { 
    // restaurant, 
    setPage } = props;

  // const onSubmit = (values: any) => console.log(values);

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Кальян </h4>

      </div>
    </div>
  );
}

export default HookahEdit;
