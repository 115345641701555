import React from 'react';

import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { toDate } from 'utils/toDate.util';
import { toStatusOrder } from 'components/toStatus';

interface IProps {
  coupon: any
  showQuestionnaire: (questionnaireId: string, couponId: string) => void;
}

export const CouponView: React.FC<IProps> = (props: any) => {
  const { coupon, showQuestionnaire } = props;

  const handleShowQuestions = () => {
    showQuestionnaire(coupon?.coupon?.questionnaireId, coupon?.coupon.id)
    // getQuestionnairesTemplateById(item.questionnaireId, item.id)
  }

  if(!coupon) return <></>;

  return (
    <>
      <div className="col-12 text-center">
        <p className="p-0">
          Услуга 
          <i> тайного покупателя </i> 
          
          {coupon.coupon.state === 'confirm' ? (
            <span style={{color: 'rgb(27 178 38)'}}><u> запущена </u></span>
          ) : ( <span style={{color: 'red'}}><u> не запущена </u></span> )}
          
        </p>
      </div>

      <Divider align="center"> Заведение </Divider>

      <div className="col-12 p-0 mt-2 text-center">
        <span><strong>{coupon.restaurant && coupon.restaurant.name}</strong></span>
      </div>

      <div className="col-12 p-0 mt-2 text-center">
        <span >{coupon.restaurant && `г.${coupon.restaurant.city}, ${coupon.restaurant.address}`}</span>
      </div>

      <Divider align="center"> Купон </Divider>

      <div className="col-12 p-0 mt-2 flex justify-content-between">
        <span>Кол-во участников: </span>
        <span>{coupon.coupon.quantityShoppers}</span>
      </div>

      <Button 
        label={coupon?.coupon.questionnaire ? `Анкета: ${coupon?.coupon.questionnaire.name}` : `Анкета удалена администрацией`} 
        className="col-12 mt-2 p-button-outlined" 
        onClick={handleShowQuestions}
        disabled={!coupon?.coupon.questionnaire}
      ></Button>

      <Divider align="center">  Чек </Divider>

      <div className="col-12 p-0 mt-2 flex justify-content-between">
        <span>Дата:</span>
        <span>{`${toDate(coupon.coupon.order.createdAt)}`}</span>
      </div>
      <div className="col-12 p-0 mt-2 flex justify-content-between">
        <span>Сумма:</span>
        <span>{coupon.coupon.order.amount}₽</span>
      </div>
      <div className="col-12 p-0 mt-2 flex justify-content-between">
        <span>Состояние:</span>
        <span 
          className={`task-status task-status-${coupon.coupon.order.state}`}
        >{toStatusOrder(coupon.coupon.order.state)}</span>
      </div>
        
      <div className="col-12 p-0 mt-2 flex justify-content-between">
        <span>Тип платежа:</span>
        {coupon.coupon.order.typePayment === 'qr' && (
          <span><i> СБП: QR-CODE </i></span>
        )}
        {coupon.coupon.order.typePayment === 'pay_acc' && (
          <span><i> Расчетный счет </i></span>
        )}
          
      </div>
    </>
  );
};

export default CouponView;