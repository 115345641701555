import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { 
  TextField,
  KladrField, 
  DateField
} from "components/form/fields";
import {
  requiredValidator,
  composeValidators,
  emailValidator,
} from "components/form/validators";
import SingleUploadImage from "components/upload/single.upload";
import { useCurrentMeQuery, useUploadAvatarMutation, usePutAccountMutation } from "app/services/auth";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";

export const AccountForm: React.FC = () => {
  const { data } = useCurrentMeQuery()
  const [ formData, setData ] = useState({ cityName: null });
  const [uploadAvatar] = useUploadAvatarMutation();
  const [ putAccount ] = usePutAccountMutation();

  useEffect(() => {
    if(data) setData( prev => {
      if(prev.cityName) return prev;
      return { ...data, cityName: null, }
    })
  }, [data])

  const upload = (file: any) => {
    if( data ) uploadAvatar({ accountId: data.id, file})
  }

  const onSubmit = async (values: any) => {

    let params = {
      accountId: values.id,
      name: values.name,
      surname: values.surname ? values.surname : null,
      email: values.email ? values.email : null,
      birthday: values.birthday ? new Date(values.birthday) : null,
      city: values.cityName ? {
        name: values.cityName.data.city,
        region: values.cityName.data.region_kladr_id,
      } : null
    };
    putAccount(params).then( (r: any) => {
      if(r?.error) return ;
      window.location.reload()
    } );
  };

  return (
    <div className="account-form">
      
      <Form
        onSubmit={onSubmit}
        initialValues={formData}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="col-12 flex flex-wrap p-0">
              
              <ScrollPanel style={{ width: '100%', height: 'calc(100dvh - 220px)' }}>
              
              <div className="col-12 md:col-3 xl:col-2 flex flex-wrap justify-content-center">
                <SingleUploadImage 
                  label={"Загрузить логотип"} 
                  onSelectFIle={upload}
                  currentImg={data ? data.avatar : undefined}
                />
              </div>

              <div className="col-12 md:col-9 xl:col-10 flex flex-wrap align-content-start">

                <div className="col-12 md:col-6 order-3 md:order-3">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="cityName"
                    label="Родной город:"
                    render={KladrField}
                    settings={{
                      defaultQuery: data?.city ? data.city.name : "",
                      selectOnBlur: true,
                      placeholder: "Введите свой город",
                      filterFromBound: "city",
                      filterToBound: "city",
                    }}
                  />
                </div>

                <div className="col-12 md:col-6 order-0 md:order-0">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="name"
                    label="Имя:"
                    render={TextField}
                    settings={{
                      placeholder: "Введите своё имя",
                    }}
                  />
                </div>

                <div className="col-12 md:col-6 order-1 md:order-1">
                  <Field
                    name="surname"
                    label="Фамилия:"
                    render={TextField}
                    settings={{
                      placeholder: "Введите свою фамилию",
                    }}
                  />
                </div>

                <div className="col-12 md:col-6 order-3 md:order-1">
                  <Field
                    validate={composeValidators(
                      // requiredValidator,
                      emailValidator
                    )}
                    name="email"
                    label="e-mail:"
                    render={TextField}
                    settings={{
                      placeholder: "Введите свою эл.почту",
                    }}
                  />
                </div>

                <div className="col-12 md:col-6 order-2 ">
                  <Field
                    name="phone"
                    label="Номер телефона:"
                    render={TextField}
                    settings={{
                      disabled: true,
                    }}
                  />
                </div>

                

                <div className="col-12 md:col-6 order-5">
                  
                    <Field
                      name="birthday"
                      render={ DateField }
                      settings={{
                        showTime: false,
                        placeholder: "__/__/____ __:__"
                      }}
                    />
                  
                </div>
              </div>

              </ScrollPanel>
              
            </div>

            <div className="col-12 flex justify-content-end p-0">
              <div className="mt-1 col-12 md:col-6 xl:col-3 p-3">
                <Button label="Сохранить" className="col-12 p-button-raised p-button-success "/>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default AccountForm;
