import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { useMediaQuery } from "react-responsive";
import { WrapperSpinner } from "components/static";
import { ScrollPanel } from "primereact/scrollpanel";
import { useGetQuestionnaireByIdMutation } from "app/services/questionnaire";
import QuestionnaireView from "./components/questionnaire.view";

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  currentId: string | null;
}

export const QuestionnaireWin: React.FC<IProps> = (props: any) => {
  const { visible, setVisible, currentId } = props;
  const [ getQuestionnairesById, {data, isLoading} ] = useGetQuestionnaireByIdMutation()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => { 
    if(!currentId) return;
    getQuestionnairesById({id: currentId})
  }, [currentId, getQuestionnairesById])

  if(!currentId) return <></>

  return (
    <Dialog 
      header={`Просмотр анкеты`} 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-6"
      style={ isMobile ? {
        width: "100vw",
        height: "100dvh",
        maxHeight: "100%",
        zIndex: '9999'
      } : {} }
      onHide={() => { 
        setVisible(false) 
      }}
    >
      <ScrollPanel style={isMobile ? { width: '100%', height: 'calc(100dvh - 144px)' } : { height: 'calc(100dvh - 200px)' }}>
        <WrapperSpinner progress={isLoading} >
          <QuestionnaireView data={data}/>
        </WrapperSpinner>
      </ScrollPanel>
    </Dialog>
  );
}

export default QuestionnaireWin;
